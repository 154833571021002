import React from 'react';
import Form from '../../../all/common/form'
import TextEditor from '../../companyProfile/editor';
import * as toast from '../../../all/toast';
import Joi from 'joi-browser';
import createPostService from "../../../services/createPostService"
import { withRouter } from 'react-router';

class CreatePost extends Form {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                id: '',
                company_id: this.props.company_id,
                title: '',
                description: '',
                image: '',
                end_date: ''
            },
            errors: {},
            loading: ''
        }
    }

    schema = {
        id: Joi.number().integer().allow('').optional(),
        company_id: Joi.number().integer().allow('').optional(),
        title: Joi.string().required().max(50).label('Company Name'),
        image: Joi.string().required(),
        description: Joi.string().allow('').optional(),
        end_date: Joi.date().required().label('End Date'),
        post_date: Joi.date().allow("").optional()
    }

    componentDidMount(){
        document.body.classList.add('datePicker')
    }

    componentWillUnmount(){
        document.body.classList.remove('datePicker')
    }

    componentWillReceiveProps(newProps) {
        let { data } = this.state;
        data.company_id = newProps.company_id
        data.id = newProps.post_id;
        this.setState({
            data
        })
        const { post_id } = newProps;
        if (post_id) {
            createPostService.getClickedPost(post_id).then(({ data: response }) => {
                this.setState({
                    data: {
                        id: response.id,
                        company_id: response.company_id,
                        title: response.title,
                        description: response.description,
                        image: response.image,
                        end_date: response.end_date,
                    }
                })
                // if(response.image !== null)
                // {
                //     this.schema = {
                //         id: Joi.number().integer().allow('').optional(),
                //         company_id: Joi.number().integer().allow('').optional(),
                //         title: Joi.string().allow('').optional(),
                //         image: Joi.string().allow('').optional(),
                //         description: Joi.string().allow('').optional(),
                //         end_date: Joi.date().allow('').optional(),
                //         post_date: Joi.date().allow("").optional()
                //     }
                // }
            }).catch(err => {
                toast.error('Faied to load post.')
            })
        }
    }

    validate1 = (end_date) => {
        const { error } = Joi.validate(end_date, this.schema.end_date, { abortEarly: false });
        if (!error) return null;
        let errors = {};
        error.details.map(p => {
            return errors[p.path[0]] = p.message
        });
        return errors;
    }

    onChangeEndDate = (date) => {
        let { data, errors } = this.state;
        const { end_date } = data;

        data.end_date = date.toISOString().slice(0, 10).replace('T', ' ');
        this.setState({
            data
        })
        errors.end_date = ''
        this.setState({
            errors
        })
    }

    autoUpload = ({ currentTarget: input }) => {
        const { errors } = this.state;
        if (input.value[0].type.split('/').includes('image')) {
            const { data } = this.state;
            this.state.request = false
            createPostService.autoUpload(input.value[0]).then(({ data: response }) => {
                data.image = response.image;
                delete errors[input.name];
                this.setState({
                    data,
                    errors
                })
            }).catch(err => {
                toast.error('Failed to add image.')
            })
        }
        else {
            toast.error('Invalid file');
        }
    }

    validateImg = (input) => {
        const { errors } = this.state;
        let depend = null;
        const errorMessage = this.validateProperty(input, depend);
        errorMessage.forEach(p => {
            errors[p.name] = p.value;
        })
        this.setState({
            errors
        })
    }

    getData = (val) => {
        let { data } = this.state;
        data.description = val
        this.setState({
            data
        })
    }
    
    submitForm = () => {
        const { id, company_id, title, description, image, end_date } = this.state.data;
        if (id) {
            createPostService.createPostPut(id, title, description, image, end_date).then(({ data }) => {
                this.setState({
                    loading: false
                })
                toast.success("Post edited successfully!");
            }).catch(err => {
                toast.error("The post was not edited. Please try again.");
                this.setState({
                    loading: false
                })
            })
        } else {
            createPostService.createPostPost(company_id, title, description, image, end_date).then(({ data }) => {
                this.setState({
                    loading: false
                })
                this.props.toggleCompanyProfile('post');
                toast.success("Post created successfully!");
            }).catch(err => {
                toast.error("The post was not created. Please try again.");
                this.setState({
                    loading: false
                })
            })
        }
    }

    onReset = () => {
        const { data } = this.state;
        data.image = null;
        this.setState({
            data,
            showImg: null
        })
    }

    render() {
        const { data, errors } = this.state;
        const { title, image, end_date, description } = data;
        return (
            <React.Fragment>
                <div className={`add-post-part`}>
                    <p>Create new post</p>
                    <form className={`add-post`} onSubmit={this.handleSubmit}>
                        <div className={`add-post-left-part`}>
                            <div className={`add-post-left`}>
                                <div className={`upload-photo-part`}>
                                    <img className={`camera`} src="../img/camera1.svg" alt="" />
                                    <div className="testgrid">
                                        <div className="mytooltip">
                                            <span className="tooltiptext">Undo photo</span>
                                        </div>
                                        <img onClick={this.onReset} className="undo" src="../img/undo-button.png" alt="" />
                                    </div>
                                    <div className={`upload-photo`}>
                                        {image &&
                                            <img className="profileImg" src={`${image}`} alt="" />
                                        }
                                        {!image &&
                                            <div className={`text`}>
                                                {this.renderUploadInput("image", null, image, 'image', this.autoUpload, errors.image, true, 1, true, "If you want to update image, just upload another one up to 10MB")}
                                            </div>
                                        }
                                    </div>
                                    <span className={`error-text ${errors.image !== '' ? '' : 'active'}`}>{errors.image}</span>
                                </div>
                            </div>
                        </div>
                        <div className={`add-post-right-part`}>
                            <div className={`add-post-top`}>
                                <div className={`post-top-input`}>
                                    {this.renderDatePicker2(false, "end_date", "end_date:", 'end_date', end_date, this.onChangeEndDate, errors.end_date, false, null, false, "End Date", true, false)}
                                    {this.renderInput("title", null, 'title', title, this.handleChange, errors.title, false, "Title")}
                                </div>
                            </div>
                            <div className={`add-post-right`}>
                                <TextEditor
                                    sendData={this.getData}
                                    description={description}
                                    error={errors.description}
                                />
                            </div>
                            <div className={`add-post-buttons`}>
                                <button type="submit" >Save</button>
                            </div>
                        </div>
                    </form>
                </div>
            </React.Fragment>
        )
    }
}

export default withRouter(CreatePost);