import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, ButtonGroup } from 'react-bootstrap';
import authService from '../../services/authService';
import userService from '../../services/userService';
import companyService from '../../services/companyService';
import getSocket from '../../all/common/socket';
import { withRouter } from "react-router";

const { REACT_APP_TOKEN_NAME: tokenName } = process.env;
const user = authService.getCurrentUser();

class NavBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            toggleNotificaiton: false,
            toggleClass: false,
            activities: [],
            profile_pic: [],
            count: 0,
            interviewTab: '',
            activePopup: '',
            page: 0,
            last_page: 1,
            items: 6,
            profile_image: null
        }
        this.onEsc = this.onEsc.bind(this)
    }

    componentDidMount() {
        if (user && user.role === 'company') {
            authService.getIsActive().then(({ data: response }) => {
                if (!response.email_confirmed) {
                    localStorage.removeItem(tokenName);
                }
            })
            companyService.getCurrentCompany().then(({ data: response }) => {
                this.setState({
                    profile_image: response.profile_picture,
                })
            })
        } else if(user && user.role === 'user') {
            userService.getCurrentUser().then(({ data: response }) => {
                this.setState({
                    profile_image: response.profile_picture,
                })
            })
        }
        this.handleScroll()
        this.getNotification()
        document.addEventListener("keydown", this.onEsc, false);
        document.body.addEventListener('click', this.onEsc, true);
    }

    getNotification = () => {
        const { page, last_page, activities, items } = this.state;
        getSocket().on("notification", (data) => {
            userService.getNotification(page + 1, items).then(({ data: response }) => {
                this.setState({
                    activities: [].concat(response.notifications),
                    count: response.count,
                    page: page + 1,
                    last_page: response.last_page
                })
            })
        });
        if (page < last_page) {
            userService.getNotification(page + 1, items).then(({ data: response }) => {
                this.setState({
                    activities: activities.concat(response.notifications),
                    count: response.count,
                    page: page + 1,
                    last_page: response.last_page
                })
            })
        }
    }

    handleScroll = () => {
        const ref = this.refs.notificationScroll
        ref.addEventListener("scroll", () => {
            if (ref.scrollTop + ref.clientHeight >= ref.scrollHeight) {
                this.getNotification('scrollPage');
            }
        });
    }

    componentWillUnmount() {
        document.addEventListener("keydown", null, false);
        document.body.addEventListener('click', null, true);
    }

    ontoggleNotificaiton = (activities) => {
        const user = authService.getCurrentUser();
        if (user && user.role === "company") {
            userService.viewAllNotifications().then(({ data: response }) => {
                this.setState({
                    toggleNotificaiton: !this.state.toggleNotificaiton,
                })
            })
        }
        if (user.role === 'user') {
            userService.viewAllUsersNotifications().then(({ data: response }) => {
                this.setState({
                    toggleNotificaiton: !this.state.toggleNotificaiton,
                })
            })
        }
        if (user.role === 'admin') {
            userService.viewAllAdminNotifications().then(({ data: response }) => {
                this.setState({
                    toggleNotificaiton: !this.state.toggleNotificaiton,
                })
            })
        }
    }

    onClickNotification = () => {
        userService.postNotification().then(({ data: response }) => {
        })
    }

    onEsc = (e) => {
        if (e.keyCode === 27) {
            this.setState({
                toggleClass: false,
                toggleNotificaiton: false
            })
        }
        else if (e.type === 'click') {
            this.setState({
                toggleClass: false,
                toggleNotificaiton: false
            })
        }
    }

    toggleClassNav = () => {
        this.setState({
            toggleClass: !this.state.toggleClass,
        })
    }

    onInterviewClick = (activity, activePopup, togglePopup) => {
        if (user.role === 'user') {
            this.props.history.push(`/notifications`)
        }
        else if (user.role === 'admin') {
            this.props.history.push(`/all-company`)
        }
        else {
            if (activity.message.includes("requested")) {
                this.props.history.push({
                    pathname: `/all-applicants/${activity.post_id}`,
                    state: {
                        interviewTab: 'Interviews',
                        activePopup: activePopup,
                        togglePopup: togglePopup,
                        postId: activity.post_id,
                        userId: activity.user_id,
                        notificationId: activity.id
                    }
                })
            }
            else {
                this.props.history.push(`/notifications`)
            }
        }
    }

    render() {
        const { data, toggleNotificaiton, activities, count, profile_image } = this.state;
        let activitiesLength = activities.length
        if (activities.length > 100) {
            activitiesLength = '99+'
        }
        return (
            <div className={`nav-bar`}>
                <div className={`header-inside`}>
                    <div className={`left-part`}>
                        <div className={'logo'}>
                            <Link to="/Home">
                                <img src="../img/new-logo.png" alt="" />
                            </Link>
                        </div>
                    </div>
                    <div className={`nav`}>
                        <div className={`nav-wrapper`}>
                            {!user ?
                                ''
                                :
                                <React.Fragment>
                                    <div className={`click`} to="">
                                        <img onClick={this.ontoggleNotificaiton} className="img" src="../img/alert.png" alt="" />
                                    </div>
                                    <div className={`count-notification ${activities.length > 100 ? 'active' : ''}`}>
                                        <span>{count}</span>
                                    </div>
                                </React.Fragment>
                            }
                            <div className={`notification-drop ${toggleNotificaiton ? 'active' : ''}`}>
                                <div className={`triangle`}></div>
                                <div ref="notificationScroll" className="notification-show-top">
                                    {activitiesLength !== 0 ?
                                        <React.Fragment>
                                            {activities.map((activity, index) =>
                                                <div key={index} onClick={this.onInterviewClick.bind(this, activity, 'activePopup', true)} className="drop-notification">
                                                    <div className="notification-img-text">
                                                        <div className="drop-notification-img">
                                                            {activity.profile_picture &&
                                                                <img src={`${activity.profile_picture}`} alt="" />
                                                            }
                                                            {!activity.profile_picture &&
                                                                <span className="initial">{activity.first_initial}{activity.last_initial}</span>
                                                            }
                                                        </div>
                                                        <div className="drop-notification-text">
                                                            <span>{activity.first_name} {activity.last_name} {activity.message}</span>
                                                        </div>
                                                    </div>
                                                    <div className="notification-date">
                                                        <span>{activity.date_notified}</span>
                                                    </div>
                                                </div>
                                            )
                                            }
                                            <div className="more-notification">
                                                <Link to="/notifications">
                                                    See All
                                                </Link>
                                            </div>
                                        </React.Fragment>
                                        :
                                        <div className="drop-notification">
                                            <p>No Notification Found</p>
                                        </div>
                                    }
                                </div>
                            </div>
                            {!user ?
                                ''
                                :
                                <Dropdown as={ButtonGroup}>
                                    <div className="profile">
                                        {profile_image &&
                                            <img className="profileImg" src={`${profile_image}`} alt="" />
                                        }
                                        {!profile_image &&
                                            <span className={`initial`}>{user.firstInitial} {user.lastInitial}</span>
                                        }
                                    </div>
                                    <div className="button">
                                        <Dropdown.Toggle split variant="" id="dropdown-split-basic" />
                                    </div>
                                    <Dropdown.Menu>
                                        <Link to="/logout">Log out</Link>
                                    </Dropdown.Menu>
                                </Dropdown>
                            }
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}

export default withRouter(NavBar);
