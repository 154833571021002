import React, { Component } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import renderHTML from 'react-render-html';

class TextEditor extends Component {
    constructor(props, value) {
        super(props);
        this.state = {
            text: "",
            error: ''
        }
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(value) {
        this.props.sendData(value);
        let valueText = value.replace(/<[^>]+>/g, '');
        if(valueText.length <= 400)
        {
            this.setState({ text: value })
        }
    }

    modules = {
        toolbar: [
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
            ['link', 'image'],
            ['clean']
        ],
    }

    formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image'
    ]

    componentWillReceiveProps(newProps) {
        if(newProps.description.replace(/<[^>]+>/g, '').length <= 400)
        {
            this.setState({
                text: newProps.description,
                error: newProps.error
            })
        }
    }

    render() {
        let value = this.state.text
        if (this.props.value)
        {
            value = this.props.value
        }
        const { error } = this.state;
        const place = ""
        return (
            <div className="text-editor">
                <ReactQuill
                    theme="snow"
                    modules={this.modules}
                    formats={this.formats}
                    value={value}
                    onChange={this.handleChange}
                    placeholder={place}
                    preserveWhitespace={true}
                />
                <div className={`react-quill-error`}>
                    <span>{error}</span>
                </div>
            </div>
        );
    }
}

export default TextEditor;