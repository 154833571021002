import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';

class CompanyModal extends Component {
    render() {
        const { company, onHide, onRemoveCompany } = this.props;
        return (
            <Modal
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.props.openModal}
                onHide={onHide} >
                <Modal.Header closeButton>
                    <Modal.Title>Warning</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete {company.name} ?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => onHide()} >
                        No
                    </Button>
                    <Button onClick={() => onRemoveCompany(company.companyId)} variant="primary">
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default CompanyModal;