import React from 'react';
import { Link } from 'react-router-dom';
import Form from '../../all/common/form'
const { REACT_APP_WEB_API_URL: web_api_url } = process.env;

class LisComponent extends Form {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    dropdownClick = (e) => {
        if (!e.target.parentNode.parentNode.className.includes('open')) {
            e.target.parentNode.parentNode.className += ' open'
            e.target.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.className += ' zIndexUser'
        } else {
            e.target.parentNode.parentNode.className = ' download-img'
            e.target.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.className = ' body-list'
        }
    }

    render() {
        const { user, company , toggleUserTab, addToShortList, removeFromShortList, schedule, onChangeSchedule , onEditSchedule, cancelInterview} = this.props;
        return (
            <React.Fragment>
                <div key={user.id} className={`body-list`}>
                    <div>
                        <div className={`user-list`}>
                                <Link to={`/user-profile/${user.username}`}>
                                    <div className="user-list-wrapper name">
                                        <span>{user.name} {user.first_name} {user.last_name}</span>
                                    </div>
                                    {toggleUserTab === 'allAplicants' || toggleUserTab === 'shortList' || toggleUserTab === 'Interviews' ?
                                        <div className="user-list-wrapper profession">
                                            <span>{user.position}</span>
                                        </div>
                                        :
                                        <React.Fragment>
                                            <div className="user-list-wrapper profession">
                                                <span>{user.email}</span>
                                            </div>
                                            <div className="user-list-wrapper img">
                                                {user.profile_picture &&
                                                    <img src={`${user.profile_picture}`} alt="" />
                                                }
                                                {!user.profile_picture &&
                                                    <span className="initial">{user.first_initial} {user.last_initial}</span>
                                                }
                                            </div>
                                        </React.Fragment>
                                    }
                                </Link>
                                <div className="user-list-wrapper resume">
                                    <div id={user.id} className={`download-img `}>
                                        {toggleUserTab === 'allAplicants' || toggleUserTab === 'shortList' ?
                                        <React.Fragment>
                                            <div className="user-list-wrapper profession">
                                                <span>{user.education}</span>
                                            </div>
                                        </React.Fragment>
                                        :
                                        ''
                                        }
                                    </div>
                                    <div>
                                    {user.date}<br/>
                                    {user.time}
                                    </div>
                                </div>
                                {company === 'comp' ?
                                    ''
                                    :
                                    <div className="user-list-wrapper resume">
                                        {toggleUserTab === 'allAplicants' &&
                                            <React.Fragment>
                                                {user.add ?
                                                <button className="user-short-list active">
                                                    Added to short list
                                                </button>
                                                :
                                                <button onClick={addToShortList.bind(this, user.id)}className="user-short-list">
                                                    Add to short list
                                                </button>
                                                }
                                            </React.Fragment>
                                        }
                                        {toggleUserTab === 'shortList' &&
                                            <React.Fragment>
                                                <button onClick={removeFromShortList.bind(this, user.id)} className="user-remove-list">
                                                    Remove from <br/>short list
                                                </button>
                                                {this.renderDatePicker3(false, "schedule", "Schedule Interview", 'schedule', schedule, onChangeSchedule.bind(this, user.id), '', false, null, false, "schedule")}
                                            </React.Fragment>
                                        }
                                        {toggleUserTab === 'Interviews' &&
                                            <React.Fragment>
                                                {this.renderDatePicker3(false, "schedule", "Reschedule interview", 'schedule', schedule, onEditSchedule.bind(this, user.id), '', false, null, false, "schedule")}
                                                <button onClick={cancelInterview.bind(this,user.id)} className="user-cancel-list">
                                                    Cancel <br/>
                                                    Interview 
                                                </button>
                                            </React.Fragment>
                                        }
                                    </div>
                                }
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default LisComponent;