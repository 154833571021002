import React, { Component } from 'react';
import GridComponent from './gridComponent';
import companyService from '../../services/companyService';
import authService from '../../services/authService';
import { Link } from 'react-router-dom';
import UserModal from './userModal';

const user = authService.getCurrentUser();

class Grid extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dropActive: false,
            data: [],
            value: '',
            selectedPage: this.props.selectedPage,
            isFavorite: [],
            favorites: [],
            notes: [],
            openModal: false,
            modalUserData: {}
        };
        this.state.data = this.props.users;
    }

    componentDidMount() {
        this.handleSearchScrollJobs();
        this.getFavorites();
        if (this.props.selectedPage === 'favorites') {
            this.setState({
                favorites: this.props.favorites
            })
            companyService.getFavoriteUsers().then(({ data: response }) => {
                this.setState({
                    favorites: [].concat(response)
                })
            })
        }
        if (this.props.selectedPage === 'notes') {
            this.setState({
                notes: this.props.notes
            })
        }
    }

    handleSearchScrollJobs = () => {
        const ref = this.refs.usersScroll
        ref.addEventListener("scroll", () => {
            if (ref.scrollTop + ref.clientHeight >= ref.scrollHeight) {
                this.props.getUsers('scrollPage');
            }
        });
    }

    getFavorites = () => {
        if (user.role === 'company') {
            companyService.getFavorites().then(({ data: response }) => {
                this.setState({
                    isFavorite: [].concat(response)
                })
            })
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.notes) {
            this.setState({
                notes: [].concat(nextProps.notes)
            })
        }
        if (nextProps.favorites) {
            this.setState({
                favorites: [].concat(nextProps.favorites)
            })
        }
    }

    setModalData = (user) => {
        this.setState({
            modalUserData: user,
            openModal: true
        })
    }

    onHide = () => {
        this.setState({
            openModal: false
        })
    }

    handleUserDeletion = (userId) => {
        this.onHide();
        this.props.onDeleteUser(userId);
    }

    render() {
        const { isToggleList, dropdownClick, users, selectedPage, last_page } = this.props;
        const { dropActive, isFavorite, favorites, notes } = this.state;
        let allUsers = '';
        if (selectedPage === 'all-users') {
            allUsers = 'all-users'
        }
        return (
            <React.Fragment>
                <div ref="usersScroll" className={`grid ${isToggleList === 'grid' ? "on" : "off"}`}>
                    {last_page === 0 &&
                        <span className="notFound">No user found!</span>
                    }
                    <div className={`my-candidates-and-notes ${allUsers}`}>
                        {selectedPage === "favorites" &&
                            <React.Fragment>
                                {favorites[0] &&
                                    favorites.map((favorite, index) =>
                                        <GridComponent
                                            indexKey={index}
                                            key={index}
                                            user={favorite}
                                            is_active={isFavorite.includes(favorite.id)}
                                            dropActive={dropActive}
                                            dropdownClick={dropdownClick}
                                            getFavorites={this.getFavorites}
                                        />
                                    )
                                }
                                {favorites.length === 0 && last_page > 0 &&
                                    Array.from({ length: 6 }, (item, index) =>
                                        <div key={index} className={`user-grid loading`}>
                                            <div className={`shadow-loading`}></div>
                                            <div className="top">
                                                <span className="initial">EX</span>
                                            </div>
                                            <div className="favorit-star">
                                                <img className={``} src="../img/star-noncolor.png" alt="" />
                                            </div>
                                            <div className="middle">
                                                <span></span>
                                                <span className="role" ></span>
                                                {/* <span className="role" >{user.id}</span> */}
                                                <div className={`middle-buttons`}>
                                                    <div className={'download-img'}>
                                                        <div className="testgrid">
                                                            <div className="mytooltip">
                                                                <p className="tooltiptext">Download CV</p>
                                                            </div>
                                                            <img src="../img/download-button.png" alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <Link className="bottom" >View Profile</Link>
                                        </div>
                                    )
                                }
                            </React.Fragment>
                        }

                        {selectedPage === "notes" &&
                            <React.Fragment>
                                {notes[0] &&
                                    notes.map((favorite, index) =>
                                        <GridComponent
                                            indexKey={index}
                                            key={index}
                                            user={favorite}
                                            is_active={isFavorite.includes(favorite.id)}
                                            dropActive={dropActive}
                                            dropdownClick={dropdownClick}
                                            getFavorites={this.getFavorites}
                                        />
                                    )
                                }
                                {notes.length === 0 && last_page > 0 &&
                                    Array.from({ length: 6 }, (item, index) =>
                                        <div key={index} className={`user-grid loading`}>
                                            <div className={`shadow-loading`}></div>
                                            <div className="top">
                                                <span className="initial">EX</span>
                                            </div>
                                            <div className="favorit-star">
                                                <img className={``} src="../img/star-noncolor.png" alt="" />
                                            </div>
                                            <div className="middle">
                                                <span></span>
                                                <span className="role" ></span>
                                                {/* <span className="role" >{user.id}</span> */}
                                                <div className={`middle-buttons`}>
                                                    <div className={'download-img'}>
                                                        <div className="testgrid">
                                                            <div className="mytooltip">
                                                                <p className="tooltiptext">Download CV</p>
                                                            </div>
                                                            <img src="../img/download-button.png" alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <Link className="bottom" >View Profile</Link>
                                        </div>
                                    )
                                }
                            </React.Fragment>
                        }
                        {selectedPage === "all-users" &&
                            <React.Fragment>
                                {users[0] &&
                                    users.map((user, index) =>
                                        <GridComponent
                                            indexKey={index}
                                            key={index}
                                            user={user}
                                            is_active={isFavorite.includes(user.id)}
                                            dropActive={dropActive}
                                            dropdownClick={dropdownClick}
                                            getFavorites={this.getFavorites}
                                            onDeleteUser={this.props.onDeleteUser}
                                            setModalData={this.setModalData}
                                        />
                                    )
                                }
 
                                <UserModal user={this.state.modalUserData} handleUserDeletion={this.handleUserDeletion} onHide={this.onHide} openModal={this.state.openModal} />
                                {users.length === 0 && last_page > 0 &&
                                    Array.from({ length: 6 }, (item, index) =>
                                        <div key={index} className={`user-grid loading`}>
                                            <div className={`shadow-loading`}></div>
                                            <div className="top">
                                                <span className="initial">EX</span>
                                            </div>
                                            <div className="favorit-star">
                                                <img className={``} src="../img/star-noncolor.png" alt="" />
                                            </div>
                                            <div className="middle">
                                                <span></span>
                                                <span className="role" ></span>
                                                {/* <span className="role" >{user.id}</span> */}
                                                <div className={`middle-buttons`}>
                                                    <div className={'download-img'}>
                                                        <div className="testgrid">
                                                            <div className="mytooltip">
                                                                <p className="tooltiptext">Download CV</p>
                                                            </div>
                                                            <img src="../img/download-button.png" alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <Link className="bottom" >View Profile</Link>
                                        </div>
                                    )
                                }
                            </React.Fragment>
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Grid;