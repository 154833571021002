
export let hasChanges = false;

export const setHasChanges = (value,name) => {
    hasChanges = value;
}

export default {
    setHasChanges,
    hasChanges
};
