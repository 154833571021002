import React from "react";
import Form from "../../../../all/common/form";
import Joi from "joi-browser";
import * as toast from "../../../../all/toast";
import internshipService from "../../../../services/internshipService";
import { setHasChanges } from '../../../../globalVariables';
import { Dropdown } from 'semantic-ui-react'
import startMonthJson from '../../../../json/startMonth.json'
import startYearJson from '../../../../json/startYear.json'
import _ from 'lodash'
import TextEditor from '../../../userProfile/components/editor';

class InternshipForm extends Form {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        id: '',
        user_id: this.props.id,
        title: "",
        organisation: "",
        start_month: "",
        start_year: "",
        end_month: "",
        end_year: "",
        city: "",
        country: "",
        description: "",
        checkboxToggle: false
      },
      errors: {},
      loading: false,
      maxCounter: 400
    };
  }

  schema = {
    user_id: Joi.number().integer().allow("").optional(),
    id: Joi.number().integer().allow("").optional(),
    title: Joi.string().required().label("Title"),
    organisation: Joi.string().required().label("Organisation"),
    start_month: Joi.string().required().label("Start Month"),
    start_year: Joi.number().integer().label("Start Year"),
    end_month: Joi.string().required().label("End Month"),
    end_year: Joi.number().integer().label("End Year"),
    city: Joi.string().required().label("City"),
    country: Joi.string().required().label("Country"),
    description: Joi.string().allow("").optional(),
    checkboxToggle: Joi.boolean().allow('').optional()
  };

  componentWillReceiveProps(newProps) {
    this.setState({
      data: {
        id: newProps.data.id,
        user_id: newProps.data.user_id,
        title: newProps.data.title,
        organisation: newProps.data.organisation,
        start_month: newProps.data.start_month,
        start_year: newProps.data.start_year,
        end_month: newProps.data.end_month,
        end_year: newProps.data.end_year,
        city: newProps.data.city,
        country: newProps.data.country,
        description: newProps.data.description,
        checkboxToggle: newProps.data.checkboxToggle
      }
    })
  }

  componentDidUpdate() {
    if (!_.isEqual(this.props.data, this.state.data)) {
      setHasChanges(true, 'internship section');
    }
    else {
      setHasChanges(false);
    }
  }

  componentWillUpdate() {
    let { data } = this.state
    if (data.checkboxToggle) {
      this.schema.end_month = Joi.string().allow(null, '').optional()
      this.schema.end_year = Joi.string().allow(null, '').optional()
    } else {
      this.schema.end_month = Joi.string().required()
      this.schema.end_year = Joi.string().required()
    }
  }


  onChangeEndDate = date => {
    const { data } = this.state;
    data.end_month = date.toISOString().slice(0, 10).replace('T', ' ');
    this.setState({ data })
  }

  onChangeStartDate = date => {
    const { data } = this.state;
    data.start_month = date.toISOString().slice(0, 10).replace('T', ' ');
    this.setState({ data })
  }


  onChangeCheckBox = event => {
    let { data } = this.state;
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    data.checkboxToggle = value;
    this.setState({
      data
    });
  };

  submitForm = () => {
    const { data } = this.state;
    this.setState({
      loading: true
    });
    const { id, user_id, title, organisation, start_month, end_month, start_year, end_year, city, country, description, checkboxToggle } = this.state.data;
    if (id) {
      internshipService.internship_history(id, user_id, title, organisation, start_month, end_month, start_year, end_year, city, country, description, checkboxToggle).then(({ response }) => {
        this.setState({
          loading: false,
        });
        this.props.saveHistory(data)
        setHasChanges(false);
        toast.success("Successfully edited internship history.");
      })
        .catch(err => {
          this.setState({
            loading: false
          });
          toast.error("Failed to edit internship history");
        });
    } else {
      internshipService.internship_history2(user_id, title, organisation, start_month, end_month, start_year, end_year, city, country, description, checkboxToggle).then(({ data: response }) => {
        data.id = response.internshipId;
        this.setState({
          loading: false,
          data
        });
        this.props.saveHistory(data)
        setHasChanges(false);
        toast.success("Successfully added internship history.");
      })
        .catch(err => {
          this.setState({
            loading: false
          });
          toast.error("Failed to add internship history");
        });
    }
  };

  onDeleteClick(id, index) {
    const { data } = this.state;
    internshipService.internship_history_delete(id).then(() => {
      data.id = "";
      this.setState({
        data
      });
      this.props.onDelete(index);
      toast.success("Successfully deleted internship history.");
    }).catch(() => {
      toast.error("Failed to delete internship history.");
    });
  }

  handleChangeStartMonth = (e, { value }) => {
    const { data, errors } = this.state;
    startMonthJson.start_Month.forEach(element => {
      if (element.value === value) {
        data.start_month = element;
      }
    });
    data.start_month = value;
    delete errors.start_month;
    this.setState({
      data
    });
  };
  handleChangeStartYear = (e, { value }) => {
    const { data, errors } = this.state;
    startYearJson.start_year.forEach(element => {
      if (element.value === value) {
        data.start_year = element;
      }
    });
    data.start_year = value;
    delete errors.start_year;
    this.setState({
      data
    });
  };
  handleChangeEndMonth = (e, { value }) => {
    const { data, errors } = this.state;
    startMonthJson.start_Month.forEach(element => {
      if (element.value === value) {
        data.end_month = element;
      }
    });
    data.end_month = value;
    delete errors.end_month;
    this.setState({
      data
    });
  };
  handleChangeEndYear = (e, { value }) => {
    const { data, errors } = this.state;
    startYearJson.start_year.forEach(element => {
      if (element.value === value) {
        data.end_year = element;
      }
    });
    data.end_year = value;
    delete errors.end_year;
    this.setState({
      data
    });
  };

  onClickError = (e) => {
    const elements = document.getElementsByClassName("invalid-tooltip");
    if (!e.target.parentNode.parentNode.className.includes('invalid-tooltip')) {
      if (elements[0]) {
        elements[0].classList.remove("invalid-tooltip");
      }
      e.target.parentNode.parentNode.className += ' invalid-tooltip'
    } else {
      e.target.parentNode.parentNode.className = 'input-form input-control is-invalid'
    }
  }

  getData = (val) => {
    let { data } = this.state;
    data.description = val
    this.setState({
      data
    })
  }

  render() {
    const { data, errors, loading, maxCounter } = this.state;
    const { id, title, organisation, start_month, start_year, end_month, end_year, city, country, description, checkboxToggle } = data;
    const { index, canAdd, addMore } = this.props;
    let counter = maxCounter - description.replace(/<[^>]+>/g, '').length;
    return (
      <div className="form-head">
        <form key={index} onSubmit={this.handleSubmit}>
          {this.renderInput("title", null, "title", title, this.handleChange, errors.title, false, "Title")}
          {this.renderInput("organisation", null, "organisation", organisation, this.handleChange, errors.organisation, false, "Organisation")}
          {this.renderInput("city", null, "city", city, this.handleChange, errors.city, false, "City")}
          {this.renderInput("country", null, "country", country, this.handleChange, errors.country, false, "Country")}
          <div className={`input-form`}>
            <Dropdown
              placeholder='Start Month'
              onChange={this.handleChangeStartMonth}
              fluid
              search
              selection
              value={start_month}
              defaultOpen={false}
              options={startMonthJson.start_Month}
            />
            <div className={`warning-circle ${errors.start_month ? 'is-invalid' : ''}`}>
              <img onClick={this.onClickError.bind(this)} src="../img/warning-sign.svg" alt="" />
            </div>
            <div className={`warning-tooltip `} >
              <p className={`error-message ${errors.start_month ? 'input-control is-invalid' : 'input-control'}`}>{errors.start_month}</p>
            </div>
          </div>
          <div className={`input-form`}>
            <Dropdown
              placeholder='Start Year'
              onChange={this.handleChangeStartYear}
              fluid
              search
              selection
              value={start_year}
              defaultOpen={false}
              options={startYearJson.start_year}
            />
            <div className={`warning-circle ${errors.start_year ? 'is-invalid' : ''}`}>
              <img onClick={this.onClickError.bind(this)} src="../img/warning-sign.svg" alt="" />
            </div>
            <div className={`warning-tooltip `} >
              <p className={`error-message ${errors.start_year ? 'input-control is-invalid' : 'input-control'}`}>{errors.start_year}</p>
            </div>
          </div>
          <div className={`input-form`}>
            <Dropdown
              placeholder='End Month'
              onChange={this.handleChangeEndMonth}
              fluid
              search
              selection
              value={end_month}
              defaultOpen={false}
              options={startMonthJson.start_Month}
              disabled={checkboxToggle}
            />
            <div className={"presentdiv"}>
              <input className={'checkbox-input'} onClick={this.onChangeCheckBox} type="checkbox" checked={checkboxToggle} />
              <label >Present</label>
            </div>
            {!checkboxToggle &&
              <React.Fragment>
                <div className={`errors_end_month warning-circle ${errors.end_month ? 'is-invalid' : ''}`}>
                  <img onClick={this.onClickError.bind(this)} src="../img/warning-sign.svg" alt="" />
                </div>
                <div className={`warning-tooltip `} >
                  <p className={`error-message ${errors.end_month ? 'input-control is-invalid' : 'input-control'}`}>{errors.end_month}</p>
                </div>
              </React.Fragment>
            }
          </div>
          <div className={`input-form`}>
            <Dropdown
              placeholder='End Year'
              onChange={this.handleChangeEndYear}
              fluid
              search
              selection
              value={end_year}
              defaultOpen={false}
              options={startYearJson.start_year}
              disabled={checkboxToggle}
            />
            {!checkboxToggle &&
              <React.Fragment>
                <div className={`warning-circle ${errors.end_year ? 'is-invalid' : ''}`}>
                  <img onClick={this.onClickError.bind(this)} src="../img/warning-sign.svg" alt="" />
                </div>
                <div className={`warning-tooltip `} >
                  <p className={`error-message ${errors.end_year ? 'input-control is-invalid' : 'input-control'}`}>{errors.end_year}</p>
                </div>
              </React.Fragment>
            }
          </div>
          <span className={`countLetters`} >Include 2-3 sentences about your experience ({`${counter}`} characters left)</span>
          <TextEditor
            sendData={this.getData}
            description={description}
            error={errors.description}
          />
          {/* {this.renderTextArea(maxCounter, "description", `Include 2-3 sentences about your experience (${counter} characters left):`, "description", description, this.handleChange, errors.description, false, false, 7)} */}
          <div className="form-buttons">
            <button type="submit" >Save</button>
            {canAdd && id &&
              <button type="button" onClick={addMore}> Add More</button>
            }
            {id &&
              <button type="button" onClick={this.onDeleteClick.bind(this, id, index)} >Delete </button>
            }
          </div>
        </form>
        {!canAdd && id &&
          <div className="bottom-line"></div>
        }
      </div>
    );
  }
}

export default InternshipForm;
