import React from 'react';
import Form from '../../../all/common/form';
import { Dropdown } from 'semantic-ui-react'
import Joi from 'joi-browser';
import * as toast from '../../../all/toast';
import aboutYouService from '../../../services/aboutYouService';
import { setHasChanges } from '../../../globalVariables';
import privacyJson from '../../../json/privacy.json'
import degreeJson from '../../../json/degreeName.json'
import _ from 'lodash'


class MoreAboutYou extends Form {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                id: this.props.data.id,
                firstName: this.props.data.firstName,
                lastName: this.props.data.lastName,
                email: this.props.data.email,
                phoneNumber: this.props.data.phoneNumber,
                birthday: this.props.data.birthday,
                country: this.props.data.country,
                city: this.props.data.city,
                address: this.props.data.address,
                postalCode: this.props.data.postalCode,
                privacy: this.props.data.privacy,
                education_level: this.props.data.education_level
            },
            tab: this.props.tab,
            errors: {},
            loading: false,
            hint: false,
            x: 0,
            y: 0
        }
    }

    componentWillReceiveProps(newProps) {
        this.setState({
            data: {
                id: newProps.data.id,
                firstName: newProps.data.firstName,
                lastName: newProps.data.lastName,
                email: newProps.data.email,
                phoneNumber: newProps.data.phoneNumber,
                birthday: newProps.data.birthday,
                country: newProps.data.country,
                city: newProps.data.city,
                address: newProps.data.address,
                postalCode: newProps.data.postalCode,
                privacy: newProps.data.privacy,
                education_level: this.props.data.education_level
            }
        })
    }

    componentDidUpdate() {
        if (!_.isEqual(this.props.data, this.state.data)) {
            setHasChanges(true, 'about you section');
        }
        else {
            setHasChanges(false);
        }
    }

    schema = {
        id: Joi.number().integer().allow('').optional(),
        firstName: Joi.string().min(3).required().label("First Name"),
        lastName: Joi.string().required().label("Last Name"),
        email: Joi.string().required().label("Email"),
        phoneNumber: Joi.number().integer().required().label("Phone Number"),
        birthday: Joi.date().required().label("Date"),
        country: Joi.string().required().label("Country"),
        city: Joi.string().required().label("City"),
        address: Joi.string().allow('').optional(),
        postalCode: Joi.number().integer().allow('').optional(),
        privacy: Joi.string().required().label("privacy"),
        education_level: Joi.string().required().label("Education Level")
    }

    submitForm = () => {
        this.setState({
            loading: true
        })
        const { id, firstName, lastName, email, phoneNumber, birthday, country, city, address, postalCode, privacy, education_level } = this.state.data;
        aboutYouService.aboutYouEdit(id, firstName, lastName, email, phoneNumber, birthday, country, city, address, postalCode, privacy, education_level).then(({ response }) => {
            this.setState({
                loading: false
            })
            this.props.saveData()
            setHasChanges(false);
            toast.success("Successfully edited user.")
        }).catch(err => {
            this.setState({
                loading: false
            })
            toast.error("Failed to edit user.")
        })
    }

    handleChangePrivacy = (e, { value }) => {
        const { data } = this.state;
        if (data.privacy !== value) {
            setHasChanges(true)
        }
        data.privacy = value;
        this.setState({
            data
        });
    };

    onChangeBirthday = date => {
        const { data } = this.state;
        data.birthday = date.toISOString().slice(0, 10).replace('T', ' ');
        this.setState({ data })
    }

    onMouseMove = (e) => {
        this.setState({
            hint: true,
            x: e.nativeEvent.offsetX,
            y: e.nativeEvent.offsetY
        })
    }

    onMouseLeave = () => {
        this.setState({
            hint: false
        })
    }

    handleChangePlatform = (e, { value }) => {
        const { data } = this.state;
        data.education_level = value;
        this.setState({
            data
        });
    };

    render() {
        const { data, errors, hint } = this.state;
        const { toggleCurrentTab, currentTab } = this.props;
        const { firstName, lastName, email, phoneNumber, birthday, country, city, address, postalCode, privacy, education_level } = data;
        return (
            <React.Fragment>
                <div className={`edit-info`} id='aboutYou' >
                    <div className={`edit-info-click ${currentTab === 'aboutYou' ? "active" : ''}`} onClick={() => toggleCurrentTab('aboutYou')} onChange={this.onChange} >
                        <span>Personal Details</span>
                        <img className="arrowImg" onClick={() => toggleCurrentTab('aboutYou')} src="../img/arrow.png" alt="" />
                    </div>
                    <div className={`edit-info-show ${currentTab === 'aboutYou' ? "active" : ''} `}>
                        <form onSubmit={this.handleSubmit} >
                            {this.renderInput("firstName", 'First Name', 'First Name', firstName, this.handleChange, errors.firstName, false, "First Name")}
                            {this.renderInput("lastName", 'Last Name', 'lastName', lastName, this.handleChange, errors.lastName, false, "Last Name")}
                            {this.renderInput("email", null, 'email', email, this.handleChange, errors.email, false, "Email")}
                            {this.renderInput("phoneNumber", null, 'phoneNumber', phoneNumber, this.handleChange, errors.phoneNumber, false, "Phone Number")}
                            {this.renderDatePicker(false, "birthday", "birthday:", 'birthday', birthday, this.onChangeBirthday, errors.birthday, false, false, false, "Birthdate")}
                            <div className={'aditional-details'}>
                                {this.renderInput("country", null, 'country', country, this.handleChange, errors.country, false, "Country")}
                                {this.renderInput("city", null, 'city', city, this.handleChange, errors.city, false, "City")}
                                {this.renderInput("address", null, 'address', address, this.handleChange, errors.address, false, "Address")}
                                {this.renderInput("postalCode", null, 'postalCode', postalCode, this.handleChange, errors.postalCode, false, "Postal Code")}
                                <div className={`input-form`}>
                                    <Dropdown
                                        placeholder='Privacy'
                                        onChange={this.handleChangePrivacy}
                                        fluid
                                        search
                                        selection
                                        value={privacy}
                                        defaultOpen={false}
                                        options={privacyJson.privacy}
                                    />
                                    <img onMouseMove={this.onMouseMove.bind(this)} onMouseLeave={this.onMouseLeave.bind(this)} className={`hint`} src="../img/icon.svg" alt="" />
                                    <div className={`tooltip2 ${hint ? 'active' : ''}`}>
                                        <div className={`left2`}>
                                            <React.Fragment>
                                                {privacy === 'public' ?
                                                    <p>Public - Public profile can be seen from all companies that are subscribed to Execruit.Best for proffessionals who are open to more opportunities.</p>
                                                    :
                                                    <p>Private - Private profile can be seen only by companies in which user has chosen to apply for a job posititon.Best suitable for professionals currently employed,
                                                open to career moves.</p>
                                                }
                                            </React.Fragment>
                                        </div>
                                    </div>
                                </div>
                                <div className={`input-form`}>
                                    <Dropdown
                                        placeholder='Education Level'
                                        onChange={this.handleChangePlatform}
                                        fluid
                                        search
                                        selection
                                        value={education_level}
                                        defaultOpen={false}
                                        options={degreeJson.degreeName}
                                    />
                                </div>
                            </div>
                            <div className="form-buttons">
                                <button type="submit">Save</button>
                            </div>
                        </form>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default MoreAboutYou;