import React from "react";
import Form from "../../../../all/common/form";
import Joi from "joi-browser";
import * as toast from "../../../../all/toast";
import socialLinksService from "../../../../services/socialLinksService";
import { Dropdown } from 'semantic-ui-react'
import socials from '../../../../json/socialPlatforms.json'
import { setHasChanges } from '../../../../globalVariables';
import _ from 'lodash'

class SocialLinksForm extends Form {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                id: '',
                user_id: this.props.id,
                social_web: "",
                social_link: "",
                social_image: ""
            },
            errors: {},
            loading: false
        };
    }

    componentDidUpdate() {
        if (!_.isEqual(this.props.data, this.state.data)) {
            setHasChanges(true, 'social section');
        }
        else {
            setHasChanges(false);
        }
    }

    componentWillReceiveProps(newProps) {
        this.setState({
            data: {
                id: newProps.data.id,
                social_web: newProps.data.social_web,
                social_link: newProps.data.social_link,
                social_image: newProps.data.social_image,
                user_id: newProps.data.user_id
            },
        });
    }

    schema = {
        user_id: Joi.number().integer().allow("").optional(),
        id: Joi.number().integer().allow("").optional(),
        social_web: Joi.string().required(),
        social_link: Joi.string().required(),
        social_image: Joi.string().allow("").optional(),
    };

    submitForm = () => {
        const { data } = this.state;
        this.setState({
            loading: true
        });
        const { id, user_id, social_web, social_link, social_image } = this.state.data;
        if (id) {
            socialLinksService.putSocialLink(id, user_id, social_web, social_link, social_image).then(({ response }) => {
                this.setState({
                    loading: false,
                });
                this.props.saveHistory(data)
                setHasChanges(false);
                toast.success("Successfully edited social link.");
            })
                .catch(err => {
                    this.setState({
                        loading: false
                    });
                    toast.error("Failed to edit social link");
                });
        } else {
            socialLinksService.postSocialLink(user_id, social_web, social_link, social_image).then(({ data: response }) => {
                data.id = response.socialId;
                this.setState({
                    loading: false,
                    data
                });
                this.props.saveHistory(data)
                setHasChanges(false);
                toast.success("Successfully added social link.");
            })
                .catch(err => {
                    this.setState({
                        loading: false
                    });
                    toast.error("Failed to add social link.");
                });
        }
    };

    onDeleteClick(id, index) {
        const { data } = this.state;
        socialLinksService.deleteSocialLink(id).then(() => {
            data.id = "";
            this.setState({
                data
            });
            this.props.onDelete(index);
        }).catch(() => {
            toast.error("Failed to delete social link.");
        });
    }

    handleChangePlatform = (e, { value }) => {
        const { data, errors } = this.state;
        let social = null;
        socials.socialPlatforms.forEach(element => {
            if (element.value === value) {
                social = element;
            }
        });
        data.social_web = value;
        data.social_image = social.image;
        delete errors.social_web;
        this.setState({
            data
        });
    };

    onClickError = (e) => {
        const elements = document.getElementsByClassName("invalid-tooltip");
        if (!e.target.parentNode.parentNode.className.includes('invalid-tooltip')) {
            if (elements[0]) {
                elements[0].classList.remove("invalid-tooltip");
            }
            e.target.parentNode.parentNode.className += ' invalid-tooltip'
        } else {
            e.target.parentNode.parentNode.className = 'input-form input-control is-invalid'
        }
    }
    render() {
        const { data, errors, loading } = this.state;
        const { id, social_web, social_link } = data;
        const { index, canAdd, addMore } = this.props;
        return (
            <div className="form-head">
                <form key={index} onSubmit={this.handleSubmit}>
                    <div className={`input-form grid-2`}>
                        <Dropdown
                            placeholder='Select Social Platform'
                            onChange={this.handleChangePlatform}
                            fluid
                            search
                            selection
                            value={social_web}
                            defaultOpen={false}
                            options={socials.socialPlatforms}
                        />
                        <div className={`warning-circle ${errors.social_web ? 'is-invalid' : ''}`}>
                            <img onClick={this.onClickError.bind(this)} src="../img/warning-sign.svg" alt="" />
                        </div>
                        <div className={`warning-tooltip `} >
                            <p className={`error-message ${errors.social_web ? 'input-control is-invalid' : 'input-control'}`}>{errors.social_web}</p>
                        </div>
                    </div>
                    {this.renderInput("social_link", null, "social_link", social_link, this.handleChange, errors.social_link, false, "www.example.com")}
                    <div className="form-buttons">
                        <button type="submit" >Save</button>
                        {canAdd && id &&
                            <button type="button" onClick={addMore}> Add More</button>
                        }
                        {id &&
                            <button type="button" onClick={this.onDeleteClick.bind(this, id, index)} >Delete </button>
                        }
                    </div>
                </form>
                {!canAdd && id &&
                    <div className="bottom-line"></div>
                }
            </div>
        );
    }
}

export default SocialLinksForm;
