import React, { Component } from 'react';


class PrivacyPolicy extends Component {
    state = {}
    render() {
        return (
            <React.Fragment>
                <div className="term-and-conditions">
                   <h1>Terms Of Use &amp; Privacy Policy</h1>
                   <div>
                   <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                       In arcu cursus euismod quis viverra nibh cras. Felis donec et odio pellentesque diam volutpat commodo sed. Ipsum a arcu cursus vitae congue mauris. 
                       Nibh tortor id aliquet lectus proin. Egestas erat imperdiet sed euismod nisi. Amet mattis vulputate enim nulla aliquet porttitor lacus luctus. 
                       Etiam erat velit scelerisque in dictum non. In tellus integer feugiat scelerisque varius morbi. Proin sed libero enim sed faucibus turpis in eu. 
                       Sit amet est placerat in egestas erat imperdiet. Tellus integer feugiat scelerisque varius morbi enim nunc. Vulputate ut pharetra sit amet aliquam. 
                       Adipiscing tristique risus nec feugiat in fermentum posuere urna nec. Volutpat sed cras ornare arcu dui vivamus arcu felis bibendum. 
                       Curabitur vitae nunc sed velit. Neque aliquam vestibulum morbi blandit cursus risus. Dictum sit amet justo donec.</p>
                    <p>Pellentesque habitant morbi tristique senectus et netus et malesuada fames. Accumsan in nisl nisi scelerisque eu ultrices vitae. 
                        Feugiat nibh sed pulvinar proin. At ultrices mi tempus imperdiet. Ac turpis egestas sed tempus. 
                        Adipiscing enim eu turpis egestas pretium aenean pharetra magna. Placerat orci nulla pellentesque dignissim enim sit amet. 
                        Pellentesque habitant morbi tristique senectus. Aenean pharetra magna ac placerat vestibulum lectus mauris. 
                        Ac ut consequat semper viverra. Eget gravida cum sociis natoque penatibus et magnis dis parturient. 
                        Dui faucibus in ornare quam viverra orci. Nulla aliquet enim tortor at auctor urna nunc. Iaculis nunc sed augue lacus viverra vitae congue.
                        sa id neque aliquam vestibulum morbi blandit cursus. Dolor purus non enim praesent elementum facilisis. 
                        Ultrices vitae auctor eu augue ut lectus arcu bibendum at.</p>
                    <p>Sit amet risus nullam eget felis eget. Elementum pulvinar etiam non quam lacus suspendisse faucibus interdum posuere. Nec dui nunc mattis enim. Leo urna molestie at elementum eu facilisis. Id volutpat lacus laoreet non curabitur gravida arcu. Rhoncus urna neque viverra justo nec ultrices dui. Malesuada fames ac turpis egestas maecenas pharetra convallis. Nec ultrices dui sapien eget mi proin sed libero. Arcu vitae elementum curabitur vitae nunc sed velit dignissim. Tellus in hac habitasse platea. Turpis massa tincidunt dui ut ornare lectus sit. Feugiat sed lectus vestibulum mattis ullamcorper velit. Cursus risus at ultrices mi tempus imperdiet nulla malesuada pellentesque. Ullamcorper sit amet risus nullam eget felis.</p>
                    <p>Aliquet sagittis id consectetur purus ut faucibus pulvinar elementum integer. Aliquet lectus proin nibh nisl condimentum id. Amet porttitor eget dolor morbi non arcu risus. Tellus integer feugiat scelerisque varius morbi. In arcu cursus euismod quis viverra nibh cras. Justo nec ultrices dui sapien eget. At ultrices mi tempus imperdiet nulla malesuada pellentesque elit eget. Amet commodo nulla facilisi nullam vehicula ipsum a arcu cursus. Quis varius quam quisque id diam vel. Nec sagittis aliquam malesuada bibendum arcu vitae elementum curabitur vitae. Ac placerat vestibulum lectus mauris ultrices eros in cursus turpis. Imperdiet proin fermentum leo vel orci. Nulla pellentesque dignissim enim sit amet venenatis. Pharetra sit amet aliquam id diam maecenas ultricies mi eget. Eu lobortis elementum nibh tellus molestie nunc non. Mattis vulputate enim nulla aliquet porttitor lacus. Nibh tellus molestie nunc non blandit massa enim nec. Sit amet purus gravida quis blandit.</p>
                    <p>Euismod quis viverra nibh cras pulvinar mattis nunc sed. Urna neque viverra justo nec ultrices dui sapien eget mi. Pulvinar etiam non quam lacus suspendisse faucibus interdum. Condimentum mattis pellentesque id nibh tortor id aliquet. Nullam non nisi est sit amet facilisis magna etiam. Dolor sit amet consectetur adipiscing elit pellentesque habitant morbi tristique. Magna fermentum iaculis eu non diam phasellus. Id faucibus nisl tincidunt eget nullam non. Aliquet lectus proin nibh nisl condimentum id venenatis a. Tellus molestie nunc non blandit massa. Fusce ut placerat orci nulla pellentesque dignissim. In tellus integer feugiat scelerisque varius. Nulla pharetra diam sit amet nisl suscipit adipiscing bibendum. Est ullamcorper eget nulla facilisi. Lectus nulla at volutpat diam ut venenatis. Varius vel pharetra vel turpis. At varius vel pharetra vel turpis nunc eget lorem dolor. Non enim praesent elementum facilisis leo.</p>
                    <p>Morbi non arcu risus quis. Aliquam etiam erat velit scelerisque in dictum non. Lectus magna fringilla urna porttitor rhoncus dolor purus. Sit amet cursus sit amet. Amet massa vitae tortor condimentum lacinia. Aliquam malesuada bibendum arcu vitae elementum curabitur vitae. Nisi porta lorem mollis aliquam ut porttitor leo a. Felis bibendum ut tristique et egestas quis. Vulputate ut pharetra sit amet. Metus dictum at tempor commodo ullamcorper a lacus vestibulum.</p>
                    <p>Integer enim neque volutpat ac. In metus vulputate eu scelerisque felis imperdiet proin fermentum leo. Dictumst vestibulum rhoncus est pellentesque elit ullamcorper. Viverra maecenas accumsan lacus vel facilisis volutpat est velit. Sit amet consectetur adipiscing elit pellentesque habitant morbi tristique. Gravida neque convallis a cras semper auctor neque vitae. Aenean euismod elementum nisi quis eleifend quam. Dictum varius duis at consectetur lorem donec massa sapien. Tellus id interdum velit laoreet id donec ultrices. Feugiat nibh sed pulvinar proin gravida hendrerit lectus. Velit laoreet id donec ultrices tincidunt arcu non. Duis ultricies lacus sed turpis tincidunt id. Ullamcorper eget nulla facilisi etiam dignissim diam quis enim. Ultricies mi eget mauris pharetra et ultrices neque. Aliquet enim tortor at auctor urna nunc id cursus.</p>
                   </div>
                </div>
            </React.Fragment>
        );
    }
}

export default PrivacyPolicy;