import React, { Component } from 'react';
import NavBar from '../../nav-bar/nav-bar';
import LeftMenu from '../../left-menu/left-menu';
import authService from '../../../services/authService';
import userService from '../../../services/userService';
import getSocket from '../../../all/common/socket';
import createPostService from '../../../services/createPostService';
import * as toast from './../../../all/toast';
import PopupSchedule from '../../../components/popup/schedulePopUp'
import {Link} from 'react-router-dom'

const user = authService.getCurrentUser();

class Notification extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            value: '',
            isToggleOn: true,
            isToggleOnMobile: true,
            toggleNotificaiton: false,
            activities: [],
            togglePopup: false,
            postId:'',
            page: 0,
            last_page:1,
            items: 15
        };
        this.state.data = this.props.users;
        
    }

    componentDidMount() {
        this.handleScroll()
        this.getNotification()
    }

    getNotification = () => {
        const { page, last_page, activities, items } = this.state;
        getSocket().on("notification", (data) => {
            userService.getNotification(page+1, items).then(({ data: response }) => {
                this.setState({
                    activities: [].concat(response.notifications),
                    count: response.count,
                    page:page+1,
                    last_page: response.last_page
                })
            })
        });
        if(page < last_page)
        {
            userService.getNotification(page+1, items).then(({ data: response }) => {
                this.setState({
                    activities: activities.concat(response.notifications),
                    count: response.count,
                    page:page+1,
                    last_page: response.last_page
                })
            })
        }
    }

    handleScroll = () => {
        const ref = this.refs.notificationScroll
        ref.addEventListener("scroll", () => {
            if (ref.scrollTop + ref.clientHeight >= ref.scrollHeight) {
                this.getNotification('scrollPage');
            }
        });
    }

    ontoggleNotificaiton = () => {
        this.setState({
            toggleNotificaiton: !this.state.toggleNotificaiton
        })
    }
    toggleLeftMenu = () => {
        this.setState({
            isToggleOn: !this.state.isToggleOn
        });
    };
    toggleLeftMenuMobile = () => {
        this.setState({
            isToggleOnMobile: !this.state.isToggleOnMobile
        });
    };

    onTogglePopup = (postId) => {
        this.setState({
            togglePopup: !this.state.togglePopup,
            postId: postId
        })   
    }

    onRemovePopUp = () => {
        this.setState({
            togglePopup: false
        })
    }

    onInterviewClick = (activity, activePopup, togglePopup) => {
        this.props.history.push({
            pathname: `/all-applicants/${activity.post_id}`,
            state: { 
                interviewTab: 'Interviews',
                activePopup: activePopup,
                togglePopup: togglePopup,
                postId: activity.post_id,
                userId: activity.user_id,
                notificationId: activity.id
            }
        })
    }

    render() {
        const { isToggleOn, isToggleOnMobile, activities, togglePopup, postId } = this.state;
        return (
            <React.Fragment>
                <NavBar />
                <LeftMenu
                    toggleLeftMenu={this.toggleLeftMenu}
                    isToggleOn={isToggleOn}
                    toggleLeftMenuMobile={this.toggleLeftMenuMobile}
                    isToggleOnMobile={isToggleOnMobile}
                />
                <PopupSchedule togglePopup={togglePopup} postId={postId} userId={user.id} onRemovePopUp={this.onRemovePopUp} />
                <div ref="notificationScroll" className={`notification-part`}>
                    <div className={`notification-top`}>
                        <div className={`your-notification-left`}>
                            <div className={`your-notification`}>
                                <div className={`notification-bell`}>
                                    <img class="img" src="../img/alert.png" alt="" />
                                </div>
                                <span>Your Notifications</span>
                            </div>
                        </div>
                    </div>
                    <div className={`notification-bottom`}>
                        <div className={`notification-first-part`}>
                            <p>ALL NOTIFICATIONS</p>
                        </div>
                        <div className={`notification-second-part`}>
                            {activities.map((activity, index) =>
                                <div className={`display-notification`}>
                                    <div className={`check-notification checkbox`}>
                                        {/* <input type="checkbox" id="checkbox_2"  />
                                        <label for="checkbox_2"></label> */}
                                    </div>
                                    <div className={`notification-from`}>
                                        {activity.profile_picture &&
                                            <img src={`${activity.profile_picture}`} alt="" />
                                        }
                                        {!activity.profile_picture &&
                                            <span className="initial">{activity.first_initial}{activity.last_initial}</span>
                                        }
                                    </div>
                                    <div className={`notification-message`}>
                                        {activity.message.includes("requested") ?
                                            <React.Fragment>
                                                <Link to={`/all-applicants/${activity.post_id}`}>
                                                    <span>{activity.first_name} {activity.last_name}</span><br />
                                                    <span>{activity.message}</span>
                                                </Link>
                                            </React.Fragment>
                                            :
                                            <React.Fragment>
                                                <span>{activity.first_name} {activity.last_name}</span><br />
                                                <span>{activity.message}</span>
                                            </React.Fragment>
                                        }
                                        
                                    </div>
                                    <div className={`notification-follow`}>
                                    {activity.message.includes("requested") &&
                                        <button>
                                            <span onClick={this.onInterviewClick.bind(this, activity, 'activePopup', true)} >View</span>
                                        </button> 
                                    }
                                    </div>
                                    <div className={`notification-follow`}>
                                        {user.role === 'user' && !activity.message.includes("cancel") &&
                                            <button onClick={() => this.onTogglePopup(activity.post_id)}>
                                                Request reschedule
                                            </button> 
                                        }
                                    </div>
                                    <div className={`notification-follow`}>

                                    </div>
                                    <div className={`delete-notification`}>

                                    </div>
                                    <div>
                                        <p> {activity.date_notified}</p>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Notification;