import React from "react";
import Form from "../../../../all/common/form";
import Joi from "joi-browser";
import * as toast from "../../../../all/toast";
import customSectionService from "../../../../services/customSectionService"
import { setHasChanges } from '../../../../globalVariables';
import { Dropdown } from 'semantic-ui-react'
import startMonthJson from '../../../../json/startMonth.json'
import startYearJson from '../../../../json/startYear.json'
import additionalInformationJson from '../../../../json/additionalInformation.json'
import _ from 'lodash'
import TextEditor from '../../../userProfile/components/editor';

class CustomForm extends Form {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        activity_name: "",
        checkboxToggle: "",
        city: "",
        custom_options: "",
        description: "",
        end_month: "",
        end_year: "",
        id: '',
        show_input_field: '',
        start_month: "",
        start_year: "",
        user_id: "",
      },
      errors: {},
      loading: false,
      toggleInput: false,
      maxCounter: 400
    };
  }

  schema = {
    user_id: Joi.number().integer().allow("").optional(),
    id: Joi.number().integer().allow("").optional(),
    custom_options: Joi.string().required().min(3).label("Options"),
    activity_name: Joi.string().required().min(3).label("Activity Name"),
    city: Joi.string().required().label("City"),
    start_month: Joi.string().required().label("Start Month"),
    start_year: Joi.number().integer().label("Start Year"),
    checkboxToggle: Joi.boolean().allow('').optional(),
    end_month: Joi.string().allow('').optional(),
    end_year: Joi.number().integer().allow('').optional(),
    description: Joi.string().allow("").optional(),
    index: Joi.number().integer().allow("").optional(),
    show_input_field: Joi.string().allow('').optional().label("link"),
    checked: Joi.boolean().allow('').optional()
  };

  componentWillReceiveProps(newProps) {
    this.setState({
      data: {
        activity_name: newProps.data.activity_name,
        checkboxToggle: newProps.data.checkboxToggle,
        city: newProps.data.city,
        custom_options: newProps.data.custom_options,
        description: newProps.data.description,
        end_month: newProps.data.end_month,
        end_year: newProps.data.end_year,
        id: newProps.data.id,
        show_input_field: newProps.data.show_input_field,
        start_month: newProps.data.start_month,
        start_year: newProps.data.start_year,
        user_id: newProps.id
      },
    })
  }
  componentDidUpdate() {
    if (!_.isEqual(this.props.data, this.state.data)) {
      setHasChanges(true, 'custom section');
    }
    else {
      setHasChanges(false);
    }
  }

  componentWillUpdate() {
    let { data } = this.state
    if (data.checkboxToggle) {
      this.schema.end_month = Joi.string().allow(null, '').optional()
      this.schema.end_year = Joi.string().allow(null, '').optional()
    } else {
      this.schema.end_month = Joi.string().required().label("End Month")
      this.schema.end_year = Joi.string().required().label("End Year") 
    }
  }

  onChangeCheckBox = event => {
    let { data } = this.state;
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    data.checkboxToggle = value;
    this.setState({
      data
    });
  };

  submitForm = () => {
    const { data } = this.state;
    this.setState({
      loading: true
    });
    const { id, user_id, custom_options, activity_name, city, start_month, end_month, start_year, end_year, description, show_input_field, checkboxToggle } = this.state.data;
    if (id) {
      customSectionService.custom_history(id, user_id, custom_options, activity_name, city, start_month, end_month, start_year, end_year, description, show_input_field, checkboxToggle).then(({ response }) => {
        this.setState({
          loading: false,
        });
        this.props.saveHistory(data)
        setHasChanges(false);
        toast.success("Successfully edited custom history.");
      })
        .catch(err => {
          this.setState({
            loading: false
          });
          toast.error("Failed to edit custom history");
        });
    } else {
      customSectionService.custom_history2(user_id, custom_options, activity_name, city, start_month, end_month, start_year, end_year, description, show_input_field, checkboxToggle).then(({ data: response }) => {
        data.id = response.customId;
        this.setState({
          loading: false,
          data
        });
        this.props.saveHistory(data)
        setHasChanges(false);
        toast.success("Successfully added custom history.");
      })
        .catch(err => {
          this.setState({
            loading: false
          });
          toast.error("Failed to add custom history");
        });
    }
  };

  onDeleteClick(id, index) {
    const { data } = this.state;
    customSectionService.custom_history_delete(id).then(() => {
      this.setState({
        data: {
          activity_name: "",
          checkboxToggle: this.props.data.checkboxToggle,
          city: "",
          custom_options: "",
          description: "",
          end_month: "",
          end_year: "",
          id: '',
          show_input_field: '',
          start_month: "",
          start_year: "",
          user_id: this.props.id
        }
      });
      this.props.onDelete(index);
      this.forceUpdate()
    }).catch(() => {
      toast.error("Failed to delete custom history.");
    });
  }

  handleChangeStartMonth = (e, { value }) => {
    const { data, errors } = this.state;
    startMonthJson.start_Month.forEach(element => {
      if (element.value === value) {
        data.start_month = element;
      }
    });
    data.start_month = value;
    delete errors.start_month;
    this.setState({
      data
    });
  };

  handleChangeStartYear = (e, { value }) => {
    const { data, errors } = this.state;
    startYearJson.start_year.forEach(element => {
      if (element.value === value) {
        data.start_year = element;
      }
    });
    data.start_year = value;
    delete errors.start_year;
    this.setState({
      data
    });
  };

  handleChangeEndMonth = (e, { value }) => {
    const { data, errors } = this.state;
    startMonthJson.start_Month.forEach(element => {
      if (element.value === value) {
        data.end_month = element;
      }
    });
    data.end_month = value;
    delete errors.end_month;
    this.setState({
      data
    });
  };

  handleChangeEndYear = (e, { value }) => {
    const { data, errors } = this.state;
    startYearJson.start_year.forEach(element => {
      if (element.value === value) {
        data.end_year = element;
      }
    });
    data.end_year = value;
    delete errors.end_year;
    this.setState({
      data
    });
  };

  handleChangeOptions = (e, { value }) => {
    const { data, errors } = this.state;
    additionalInformationJson.additional_Information.forEach(element => {
      if (element.value === value) {
        data.custom_options = element;
      }
    });
    if (value === "Publication") {
      this.setState({
        toggleInput: true
      })
    } else {
      this.setState({
        toggleInput: false
      })
    }
    data.custom_options = value;
    delete errors.custom_options;
    this.setState({
      data
    });
  };

  onClickError = (e) => {
    const elements = document.getElementsByClassName("invalid-tooltip");
    if (!e.target.parentNode.parentNode.className.includes('invalid-tooltip')) {
      if (elements[0]) {
        elements[0].classList.remove("invalid-tooltip");
      }
      e.target.parentNode.parentNode.className += ' invalid-tooltip'
    } else {
      e.target.parentNode.parentNode.className = 'input-form input-control is-invalid'
    }
  }

  getData = (val) => {
    let { data } = this.state;
    data.description = val
    this.setState({
      data
    })
  }

  render() {
    const { data, errors, loading, toggleInput, maxCounter } = this.state;
    const { id, custom_options, activity_name, city, start_month, end_month, start_year, end_year, description, show_input_field, checkboxToggle } = data;
    const { index, canAdd, addMore } = this.props;
    let counter = 0
    if (description) {
      counter = maxCounter - description.replace(/<[^>]+>/g, '').length;
    }
    return (
      <div className="form-head">
        <form key={index} onSubmit={this.handleSubmit}>
          <div className={`input-form`}>
            <Dropdown
              placeholder='Type'
              onChange={this.handleChangeOptions}
              fluid
              search
              selection
              value={custom_options}
              defaultOpen={false}
              options={additionalInformationJson.additional_Information}
            />
            <div className={`warning-circle ${errors.custom_options ? 'is-invalid' : ''}`}>
              <img onClick={this.onClickError.bind(this)} src="../img/warning-sign.svg" alt="" />
            </div>
            <div className={`warning-tooltip `} >
              <p className={`error-message ${errors.custom_options ? 'input-control is-invalid' : 'input-control'}`}>{errors.custom_options}</p>
            </div>
          </div>
          {toggleInput &&
            this.renderInput("show_input_field", null, "show_input_field", show_input_field, this.handleChange, errors.show_input_field, false, "Link")
          }
          {this.renderInput("activity_name", null, "activity_name", activity_name, this.handleChange, errors.activity_name, false, "Activity Name")}
          {this.renderInput("city", null, "city", city, this.handleChange, errors.city, false, "City")}
          <div className={`input-form`}>
            <Dropdown
              placeholder='Start Month'
              onChange={this.handleChangeStartMonth}
              fluid
              search
              selection
              value={start_month}
              defaultOpen={false}
              options={startMonthJson.start_Month}
            />
            <div className={`warning-circle ${errors.start_month ? 'is-invalid' : ''}`}>
              <img onClick={this.onClickError.bind(this)} src="../img/warning-sign.svg" alt="" />
            </div>
            <div className={`warning-tooltip `} >
              <p className={`error-message ${errors.start_month ? 'input-control is-invalid' : 'input-control'}`}>{errors.start_month}</p>
            </div>
          </div>
          <div className={`input-form`}>
            <Dropdown
              placeholder='Start Year'
              onChange={this.handleChangeStartYear}
              fluid
              search
              selection
              value={start_year}
              defaultOpen={false}
              options={startYearJson.start_year}
            />
            <div className={`warning-circle ${errors.start_year ? 'is-invalid' : ''}`}>
              <img onClick={this.onClickError.bind(this)} src="../img/warning-sign.svg" alt="" />
            </div>
            <div className={`warning-tooltip `} >
              <p className={`error-message ${errors.start_year ? 'input-control is-invalid' : 'input-control'}`}>{errors.start_year}</p>
            </div>
          </div>
          <div className={`input-form`}>
            <Dropdown
              placeholder='End Month'
              onChange={this.handleChangeEndMonth}
              fluid
              search
              selection
              value={end_month}
              defaultOpen={false}
              options={startMonthJson.start_Month}
              disabled={checkboxToggle}
            />
            <div className={"presentdiv"}>
              <input className={'checkbox-input'} onClick={this.onChangeCheckBox} type="checkbox" checked={checkboxToggle} />
              <label >Present</label>
            </div>
            {!checkboxToggle &&
              <React.Fragment>
                <div className={`errors_end_month warning-circle ${errors.end_month ? 'is-invalid' : ''}`}>
                  <img onClick={this.onClickError.bind(this)} src="../img/warning-sign.svg" alt="" />
                </div>
                <div className={`warning-tooltip `} >
                  <p className={`error-message ${errors.end_month ? 'input-control is-invalid' : 'input-control'}`}>{errors.end_month}</p>
                </div>
              </React.Fragment>
            }
          </div>
          <div className={`input-form`}>
            <Dropdown
              placeholder='End Year'
              onChange={this.handleChangeEndYear}
              fluid
              search
              selection
              value={end_year}
              defaultOpen={false}
              options={startYearJson.start_year}
              disabled={checkboxToggle}
            />
            {!checkboxToggle &&
              <React.Fragment>
                <div className={`warning-circle ${errors.end_year ? 'is-invalid' : ''}`}>
                  <img onClick={this.onClickError.bind(this)} src="../img/warning-sign.svg" alt="" />
                </div>
                <div className={`warning-tooltip `} >
                  <p className={`error-message ${errors.end_year ? 'input-control is-invalid' : 'input-control'}`}>{errors.end_year}</p>
                </div>
              </React.Fragment>
            }
          </div>
          {/* {this.renderTextArea(maxCounter, "description", `Include 2-3 sentences about your experience (${counter} characters left):`, "description", description, this.handleChange, errors.description, false, false, 7)} */}
          <span className={`countLetters`} >Include 2-3 sentences about your experience ({`${counter}`} characters left)</span>          
          <TextEditor
            sendData={this.getData}
            description={description}
            error={errors.description}
          />
          <div className="form-buttons">
            <button type="submit" >Save</button>
            {canAdd && id &&
              <button type="button" onClick={addMore}>Add More</button>
            }
            {id &&
              <button type="button" onClick={this.onDeleteClick.bind(this, id, index)} >Delete</button>
            }
          </div>
        </form>
        {!canAdd && id &&
          <div className="bottom-line"></div>
        }
      </div>
    );
  }
}

export default CustomForm;
