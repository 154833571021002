import React, { Component } from 'react';
import profileService from '../../../services/profileService';
import * as toast from "../../../all/toast";
import GridComponentCompany from './gridComponent';
import authService from '../../../services/authService';
import getSocket from '../../../all/common/socket'
import companyService from '../../../services/companyService';
import { Popover } from 'react-bootstrap';
import { Popup } from 'semantic-ui-react';
import CompanyModal from './companyModal';
const user = authService.getCurrentUser();

class GridCompany extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dropActive: false,
            data: [],
            value: '',
            selectedPage: this.props.selectedPage,
            isFavorite: [],
            favorites: [],
            email_confirmed: false,
            users: this.props.users,
            openModal: false,
            modalCompany: {}
        };
        this.state.data = this.props.users;
    }

    favoritesFunc = (id, is_active) => {
        profileService.putFavoriteUser(id, !is_active).then(({ response }) => {
            this.getFavorites();
            if (is_active) {
                toast.success("Successfully unfollowed user.");
            }
            else {
                toast.success("Successfully followed user.");
            }
        })
        getSocket().emit('notification');
    }


    componentDidMount() {
        this.getFavorites();
        this.setState({
            users: this.props.users
        })
    }

    componentWillReceiveProps(newProps) {
        this.setState({
            users: newProps.users
        })
    }

    getFavorites = () => {
        if (user.role === 'user') {
            profileService.getFavoritesUser().then(({ data: response }) => {
                this.setState({
                    favorites: [].concat(response)
                })
            })
        }
    }

    acceptFunc = (id) => {
        authService.confirmCompany(id).then(({ response }) => {
            this.getUsers()
            toast.success("Successfully confirmed company.");
        }).catch(err => {
            toast.error("Failed to confirm company");
        });
    }

    getUsers = () => {
        companyService.getAll().then(({ data }) => {
            this.setState({
                users: [].concat(data)
            })
        })
    }

    ignoreFunc = (id, index) => {
        const { users } = this.state;
        this.setState({
            users
        })
        authService.ignoreCompany(id).then(({ response }) => {
            toast.success("Successfully ignored company.");
            this.getUsers()
        }).catch(err => {
            toast.error("Failed to ignore company.");
        });
    }

    removeCompany = (id) => {
        companyService.removeCompany(id).then(() => {
            this.setState({
                openModal: false
            });
            toast.success('Successfully deleted company.');
            companyService.getAll().then(({ data }) => {
                this.setState({
                    users: [].concat(data)
                })
            })
        }).catch((err) => {
            this.setState({
                openModal: false
            });
            toast.error('Failed to delete company');
        })
    }

    setModalData = (companyId, name) => {
        let modalCompany = { companyId, name };
        this.setState({
            openModal: true,
            modalCompany: modalCompany
        })
    }

    onHide = () => {
        this.setState({
            openModal: false
        })
    }

    render() {
        const { isToggleList, dropdownClick } = this.props;
        const { dropActive, favorites, users, modalCompany, openModal } = this.state;
        return (
            <React.Fragment>
                <div className={`grid ${isToggleList === 'grid' ? 'on' : 'off'}`}>
                    <div>
                        <React.Fragment>
                            {users.map((user, index) =>
                                <GridComponentCompany
                                    indexKey={index}
                                    key={index}
                                    favoritesFunc={this.favoritesFunc}
                                    acceptFunc={this.acceptFunc}
                                    ignoreFunc={this.ignoreFunc}
                                    favorites={this.favorites}
                                    email_confirmed={user.email_confirmed}
                                    company={user}
                                    isActive={favorites.filter(e => e.favorite === user.id).length > 0}
                                    dropActive={dropActive}
                                    dropdownClick={dropdownClick}
                                    getFavorites={this.getFavorites}
                                    setModalData={this.setModalData}
                                />
                            )}
                            <CompanyModal
                                onHide={this.onHide}
                                company={modalCompany}
                                openModal={openModal}
                                onRemoveCompany={this.removeCompany}
                            />
                        </React.Fragment>

                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default GridCompany;