import React, { Component } from 'react';

class NavProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            // <div className="nav-box" onWheel = {() => }>
            // <div className="nav-box">
            //     <div className="nav-box-wrapper">
            //         <div className="img1"></div>
            //         <span>General Info</span>
            //     </div>
            //     <Section />
            //     <div className="nav-box-wrapper">
            //         <div className="img1"></div>
            //         <span>General Info</span>
            //     </div>
            //     <div className="nav-box-wrapper">
            //         <div className="img1"></div>
            //         <span>General Info</span>
            //     </div>
            //     <div className="nav-box-wrapper">
            //         <div className="img1"></div>
            //         <span>General Info</span>
            //     </div>
            //     <div className="nav-box-wrapper">
            //         <div className="img1"></div>
            //         <span>General Info</span>
            //     </div>
            //     <div className="nav-box-wrapper">
            //         <div className="img1"></div>
            //         <span>General Info</span>
            //     </div>
            // </div>
            <div></div>
        );
    }
}

export default NavProfile;