import React from 'react';
import createPostService from '../../services/createPostService';
import * as toast from '../../all/toast';
import getSocket from '../../all/common/socket';
import Form  from "./../../all/common/form";
import Joi from "joi-browser";
import authService from './../../services/authService';
import userService from '../../services/userService';

const user = authService.getCurrentUser();

class PopupSchedule extends Form {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                interviewMessage: '',
            },
            test: false,
            errors: {},
            loading: false,
            maxCounter: 400,
            activities: [],
            togglePopup:false,
            user_message: '',
            first_name:'',
            last_name:'',
            post_title:'',
            popupData: {
                user_message: '',
                first_name: '',
                last_name: '',
                post_title: ''
            }
        }
    }

    schema = {}

    componentDidMount()
    {
        const { postId, userId, notificationId } = this.props
        if(user && user.role === 'company')
        {
            this.schema = {
                interviewMessage:Joi.string().required()
            }
            userService.getUserMessage(notificationId).then(({ data: response }) => {
                this.setState({
                    user_message: response.user_message
                })
            })
        }else
        {
            this.schema = {
                interviewMessage:Joi.string().required()
            }
        }
    }

    
    componentWillReceiveProps(newProps)
    {
        const { popupData } = this.state
        if(user && user.role === 'company')
        {
            userService.getUserMessage(newProps.notificationId).then(({ data: response }) => {
                this.setState({
                    popupData: {
                        user_message: response.user_message,
                        first_name: response.first_name,
                        last_name: response.last_name,
                        post_title: response.post_title
                    }
                })
            })
        }else
        {
            return ''
        }
    }

    submitForm = () => {
        const { postId, userId} = this.props
        const { data } = this.state
        const { interviewMessage } = this.state.data
        createPostService.requestReschedule(interviewMessage, postId, userId).then(({ data: response }) => {
            toast.success("Interview reschedule successfully.");
            data.interviewMessage = ''
            this.setState({
                data
            })
        }).catch(err => {
            toast.error('Reschedule faild. Please try again.')
        })
        getSocket().emit('notification');
    };

    onEditSchedule = (userID, date) => {
        let { schedule } = this.state;
        schedule = date;
        this.setState({ 
            schedule 
        },
            this.EditinterviewDate(userID, schedule)
        )
    }

    render() {
        const { errors, loading, data, maxCounter, popupData } = this.state;
        const { interviewMessage } = data;
        const { togglePopup, onRemovePopUp, cancelInterview ,schedule, onEditSchedule, userId } = this.props;
        let counter = maxCounter - interviewMessage.length;
        return (
            <React.Fragment>
                <div id={'popup-id'} className={`popup ${togglePopup ? 'active' : ''}`}>
                    <div className={`popup-inside`}>
                        {user && user.role === 'user' &&
                            <form onSubmit={this.handleSubmit} >
                                {this.renderTextArea(maxCounter, "interviewMessage", `This text its going to be included in email asking to reschedule interview. (${counter} characters left):`, "", interviewMessage, this.handleChange, errors.interviewMessage, false, false, 7, 10)}
                                <div className="form-buttons popup" onClick={onRemovePopUp}>
                                    {this.renderSubmitButton("Send", loading, "")  }
                                </div>
                            </form>
                        }
                        {user && user.role === 'company' &&
                            <div className={'companyPopup'}>
                                {popupData.first_name &&
                                    <form onSubmit={this.handleSubmit} >
                                        <p>{popupData.first_name} {popupData.last_name} asked for a reschedule about post with title {popupData.post_title}:<br/> </p>
                                        <div className={'userMessage'}>
                                            {popupData.user_message} 
                                        </div>
                                        {this.renderDatePicker3(false, "schedule", "Reschedule interview", 'schedule', schedule, onEditSchedule.bind(this, userId), '', false, null, false, "schedule")}
                                        {/* <button onClick={cancelInterview.bind(this,userId)} className="user-cancel-list">
                                            Cancel <br/>
                                            Interview 
                                        </button> */}
                                    </form>
                                }
                            </div>
                        }
                       <img onClick={onRemovePopUp} className="remove-popup" src="../img/close.svg" alt="" />  
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default PopupSchedule;