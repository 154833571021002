import React from 'react';
import Form from '../../all/common/form';
import authService from '../../services/authService';
import Joi from 'joi-browser';
import * as toast from '../../all/toast'

class ResetPassword extends Form {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                password: '',
                confirmPassword: '',
                token: props.match.params.token,
            },
            errors: {},
            loading: false,
        }
    }

    schema = {
        password: Joi.string().required().min(8),
        confirmPassword: Joi.string().valid(Joi.ref('password')).required().options({
            language: {
                any: {
                    allowOnly: '!!Passwords do not match',
                }
            }
        }),
        token: Joi.string().required(),
    }

    componentWillReceiveProps() {
        const { data } = this.state;
        data.password = '';
        this.setState({
            data
        })
    }

    submitForm = () => {
        const { data } = this.state;
        this.setState({
            loading: true
        })
        authService.changePassowrd(data.password, data.token).then(({ data: response }) => {
            toast.success("Successfully changed password.")
            this.setState({
                loading: false
            })
            window.location = '/';
        }).catch(err => {
            this.setState({
                loading: false
            })
            toast.error("Failed to change password. Please try again!")
        })
    }

    handleConfirmPasswordChange = ({ currentTarget: input }) => {
        const schema = {
            password: Joi.string().required(),
            confirmPassword: Joi.string().valid(Joi.ref('password')).required().options({
                language: {
                    any: {
                        allowOnly: '!!Passwords do not match',
                    }
                }
            }),
        }
        let fieldName = input.name;
        const { data, errors } = this.state;
        const { password, confirmPassword } = data;
        let obj;
        if (fieldName === 'password') {
            obj = {
                password: input.value,
                confirmPassword
            }
            data.password = input.value;
        }
        else {
            obj = {
                password,
                confirmPassword: input.value
            }
            data.confirmPassword = input.value;
        }
        const { error } = Joi.validate(obj, schema);
        delete errors['confirmPassword'];
        delete errors['password'];
        if (!error) {
        }
        else {
            error.details.forEach(err => {
                errors[err.path[0]] = 'Passwords do not match';
            })
        }
        data[input.name] = input.value;

        this.setState({
            data,
            errors
        })
    }

    render() {
        const { data, errors, loading } = this.state;
        const { password, confirmPassword } = data;

        return (
            <React.Fragment>
                <div className={"auth"} >
                    <div className={"loginAuth active"}>
                        <div className={"login-parent"}>
                            <div className={`login-first`}>
                                <div className="login">
                                    <div className={'logo'}>
                                        <img src="../assets/img/logo.png" alt="" />
                                    </div>
                                    <div className="formlabel">
                                        <span>Reset Password
                                            <div className="div-helper"></div>
                                        </span>
                                    </div>
                                    <div className="form">
                                        <form onSubmit={this.handleSubmit} action="">
                                            {/* {this.renderInput("password", null, "password", password, this.handleChange, errors.password, false, "Password", false, "password")}
                                            {this.renderInput("confirmPassword", null, "confirmPassword", confirmPassword, this.handleChange, errors.confirmPassword, false, "Confirm Password", false, "password")} */}
                                            {this.renderInput("password", null, "password", password, this.handleChange, errors.password, false, "New Password", false, "password")}
                                            {this.renderInput("confirmPassword", null, "confirmPassword", confirmPassword, this.handleConfirmPasswordChange, errors.confirmPassword, false, "Confirm New Password", false, "password")}
                                            <div className="loginButton">
                                                {this.renderSubmitButton("Save", loading)}
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className={`login-second`}>
                                <div className="overlay"></div>
                                <img src="../img/office1.jpeg" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default ResetPassword;