import React, { Component } from 'react';
import LisComponent from '../favorites/listComponent';
import NavBar from '../nav-bar/nav-bar';
import LeftMenu from '../left-menu/left-menu';
import createPostService from '../../services/createPostService';
import * as toast from '../../all/toast';
import getSocket from '../../all/common/socket'
import PopupSchedule from '../popup/schedulePopUp';
import Filter from '../favorites/filter';
import SearchBar from '../search/search';
import searchService from '../../services/searchService';

class AllAplicants extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dropActive: false,
            users: [],
            value: '',
            isFilterOn: false,
            isToggleList: 'list',
            isToggleOn: true,
            isToggleOnMobile: true,
            toggleUserTab: 'allAplicants',
            schedule: '',
            activePopup: '',
            togglePopup: false,
            filter_search: '',
            onFilterBySearch: '',
            skillsFilterValue: '',
            languageFilter: '',
            educationFilter: '',
            experienceFilter: '',
            drivingLicense: '',
            page: 1,
            last_page:1
        };
        this.state.data = this.props.users;
    }

    componentDidMount() {
        this.getFilteredUsersNavMenu();
        if (this.props.location.state) {
            this.setState({
                toggleUserTab: this.props.location.state.interviewTab,
                activePopup: this.props.location.state.activePopup,
                togglePopup: this.props.location.state.togglePopup
            },
                this.toggleUserProfileTab(this.props.location.state.interviewTab)
            )
        }
        this.handleSearchScrollJobs()
    }

    handleSearchScrollJobs = () => {
        const {page, last_page} = this.state;
        this.refs.myscroll.addEventListener("scroll", () => {
            if (this.refs.myscroll.scrollTop + this.refs.myscroll.clientHeight >= this.refs.myscroll.scrollHeight) {
                if(page+1 <= last_page){
                    this.setState({
                        page:this.state.page+1
                    },
                        this.getFilteredUsersNavMenu
                    )
                }
            }
        });
    }

    addToShortList = (userID) => {
        let jobId = this.props.location.pathname.split('/')[2]
        createPostService.postShortApplicants(jobId, userID).then(({ data: response }) => {
            toast.success('Successfully added to short list')        
            this.getFilteredUsersNavMenu()
        }).catch(err => {
            toast.error('This user already exist in Short list.')
        })
    }

    removeFromShortList = (userID) => {
        let jobId = this.props.location.pathname.split('/')[2]
        createPostService.removeFromShortList(jobId, userID).then(({ data: response }) => {
            toast.success('Successfully removed from short list')
            // createPostService.getShortApplicants(jobId).then(({ data: response }) => {
            //     this.setState({
            //         users: [].concat(response)
            //     })
            // })
            this.getFilteredUsersNavMenu()
        }).catch(err => {
            toast.error('Failed to remove applicant from short list.')
        })
    }

    onChangeSchedule = (userID, date) => {
        let { schedule } = this.state;
        schedule = date;
        this.setState({
            schedule
        },
            this.interviewDate(userID, schedule)
        )
    }

    onEditSchedule = (userID, date) => {
        let { schedule } = this.state;
        schedule = date;
        this.setState({
            schedule
        },
            this.EditinterviewDate(userID, schedule)
        )
    }

    interviewDate = (userID, schedule) => {
        let jobId = this.props.location.pathname.split('/')[2]
        createPostService.interviewDate(jobId, userID, schedule).then(({ data: response }) => {
            toast.success('Successfully added to interview  list')
        }).catch(err => {
            toast.error('This user already exist in interview list')
        })
        getSocket().emit('notification');
    }

    EditinterviewDate = (userID, schedule) => {
        let jobId = this.props.location.pathname.split('/')[2]
        createPostService.EditinterviewDate(jobId, userID, schedule).then(({ data: response }) => {
            toast.success('Successfully edited date of interview')
            this.getFilteredUsersNavMenu()
        }).catch(err => {
            toast.error('Failed to chande date of interview.')
        })
        getSocket().emit('notification');
    }

    cancelInterview = (userID, ) => {
        let jobId = this.props.location.pathname.split('/')[2]
        createPostService.cancelInterview(jobId, userID).then(({ data: response }) => {
            toast.success('Successfully canceled interview')
            this.getFilteredUsersNavMenu()
        }).catch(err => {
            toast.error('Failed to cancel interview.')
        })
        getSocket().emit('notification');
    }

    toggleUserProfileTab = (toggleUserTab) => {
        this.setState({
            toggleUserTab: toggleUserTab,
            page: 1
        },this.getFilteredUsersNavMenu)
        
    }

    toggleFilter = () => {
        this.setState({
            isFilterOn: !this.state.isFilterOn
        });
    };

    toggleLeftMenu = () => {
        this.setState({
            isToggleOn: !this.state.isToggleOn
        });
    };

    toggleLeftMenuMobile = () => {
        this.setState({
            isToggleOnMobile: !this.state.isToggleOnMobile
        });
    };

    toggleCompanyProfile = (toggleCompany) => {
        this.setState({
            toggleCompany: toggleCompany
        })
    }

    onEditPost = (toggleCompany, id) => {
        this.setState({
            toggleCompany: toggleCompany,
            post_id: id
        })
    }

    componentWillReceiveProps(newProps) {
        this.setState({
            toggleUserTab: newProps.location.state.interviewTab,
            activePopup: newProps.location.state.activePopup,
            togglePopup: newProps.location.state.togglePopup
        },
            this.toggleUserProfileTab(newProps.location.state.interviewTab)
        )
    }

    onRemovePopUp = () => {
        this.setState({
            togglePopup: false
        })
    }

    // One Filter By All Parameters
    getFilteredUsersNavMenu = () => {
        let { skillsFilterValue, languageFilter, educationFilter, experienceFilter, drivingLicense, toggleCompany, filter_search, page, toggleUserTab } = this.state;
        let jobId = this.props.location.pathname.split('/')[2]
        if (toggleUserTab === 'allAplicants') {
            searchService.allAplicantsSearch(jobId, skillsFilterValue, languageFilter, educationFilter, experienceFilter, drivingLicense, toggleCompany, filter_search, page).then(({ data: response }) => {
                let allUsers = []
                if (response && response.applicants.length > 0) {
                    allUsers = [].concat(response.applicants);
                }
                this.setState({
                    users: allUsers,
                    last_page:response.last_page
                });
            })
        }
        else if (toggleUserTab === 'shortList') {
            searchService.allAplicantsSearchShort(jobId, skillsFilterValue, languageFilter, educationFilter, experienceFilter, drivingLicense, toggleCompany, filter_search, page).then(({ data: response }) => {
                let allUsers = []
                if (response && response.applicants.length > 0) {
                    allUsers = [].concat(response.applicants);
                }
                this.setState({
                    users: allUsers,
                    last_page:response.last_page
                });
            })
        }
        else if (toggleUserTab === 'Interviews') {
            searchService.allAplicantsSearchInterview(jobId, skillsFilterValue, languageFilter, educationFilter, experienceFilter, drivingLicense, toggleCompany, filter_search, page).then(({ data: response }) => {
                let allUsers = []
                if (response && response.applicants.length > 0) {
                    allUsers = [].concat(response.applicants);
                }
                this.setState({
                    users: allUsers,
                    last_page:response.last_page
                });
            })
        }
    }

    handleChangePlatform = (e, { value }) => {
        this.setState({
            skillsFilterValue: value
        },
            this.getFilteredUsersNavMenu
        );
    };

    // Filter By Languages
    onLanguageFilter = (e, { value }) => {
        this.setState({
            languageFilter: value
        },
            this.getFilteredUsersNavMenu
        );
    }

    // Filter By Education Degree
    onEducationFilter = (e, { value }) => {
        this.setState({
            educationFilter: value
        },
            this.getFilteredUsersNavMenu
        );
    }

    // Filter By Driving License Categoryc
    onDrivingFilter = (e, { value }) => {
        this.setState({
            drivingLicense: value
        },
            this.getFilteredUsersNavMenu
        );
    }

    // Filter By Experience Filter
    onExperienceFilter = (e, { value }) => {
        this.setState({
            experienceFilter: value
        },
            this.getFilteredUsersNavMenu
        );
    }

    // Filter By Search
    onFilterBySearch = (event) => {
        const value = event.target.value
        this.setState({
            filter_search: value
        },
            this.getFilteredUsersNavMenu
        );
    }

    render() {
        const { dropdownClick, company } = this.props;
        const { dropActive, isToggleList, isFilterOn, users, isToggleOn, isToggleOnMobile, toggleUserTab, schedule, activePopup, togglePopup, filter_search } = this.state;
        return (
            <React.Fragment>
                <NavBar
                    toggleLeftMenu={this.toggleLeftMenu}
                    isToggleOn={isToggleOn}
                />
                <LeftMenu
                    toggleLeftMenu={this.toggleLeftMenu}
                    isToggleOn={isToggleOn}
                    toggleLeftMenuMobile={this.toggleLeftMenuMobile}
                    isToggleOnMobile={isToggleOnMobile}
                />
                <SearchBar
                    filter_search={filter_search}
                    onFilterBySearch={this.onFilterBySearch}
                />
                {activePopup && activePopup === 'activePopup' &&
                    <PopupSchedule
                        togglePopup={togglePopup}
                        onRemovePopUp={this.onRemovePopUp}
                        userId={this.props.location.state.userId}
                        postId={this.props.location.state.postId}
                        cancelInterview={this.cancelInterview}
                        onEditSchedule={this.onEditSchedule}
                        notificationId={this.props.location.state.notificationId}
                    />
                }
                <div className={`favorites all-aplicants`}>
                    <div className={`nav-menu`}>
                        <div onClick={this.toggleUserProfileTab.bind(this, 'allAplicants')} className={`navmenu-button ${toggleUserTab === 'allAplicants' ? 'active' : ''}`}>
                            <span>All Aplicants</span>
                        </div>
                        <div onClick={this.toggleUserProfileTab.bind(this, 'shortList')} className={`navmenu-button ${toggleUserTab === 'shortList' ? 'active' : ''}`}>
                            <span>Short List</span>
                        </div>
                        <div onClick={this.toggleUserProfileTab.bind(this, 'Interviews')} className={`navmenu-button ${toggleUserTab === 'Interviews' ? 'active' : ''}`}>
                            <span>Interviews</span>
                        </div>
                    </div>
                    <Filter
                        toggleList={this.toggleList}
                        isToggleList={isToggleList}
                        toggleFilter={this.toggleFilter}
                        isFilterOn={isFilterOn}
                        handleChange={this.handleChangePlatform}
                        educationChange={this.onEducationFilter}
                        languageChange={this.onLanguageFilter}
                        drivingChange={this.onDrivingFilter}
                        experienceChange={this.onExperienceFilter}
                        selectedPage='favorites'
                    />
                    <div ref="myscroll" className={`list on`}>
                        <div className={`top-list`}>
                            <div className="top-list-name">
                                <span>Name</span>
                            </div>
                            <div className="top-list-name">
                                <span>Position</span>
                            </div>
                            {toggleUserTab !== 'Interviews' &&
                                <div className="top-list-name">
                                    <span>Education</span>
                                </div>
                            }
                            {toggleUserTab === 'Interviews' &&
                                <div className="top-list-name">
                                    <span>Interview Time</span>
                                </div>
                            }
                            {company === 'comp' && toggleUserTab === 'allAplicants' ?
                                ''
                                :
                                <div className="top-list-name">
                                    <span>Schedule</span>
                                </div>
                            }
                        </div>
                        <div>
                            {users.map((user, index) =>
                                <LisComponent
                                    indexKey={index}
                                    key={index}
                                    user={user}
                                    dropActive={dropActive}
                                    dropdownClick={dropdownClick}
                                    company={company}
                                    toggleUserTab={toggleUserTab}
                                    addToShortList={this.addToShortList}
                                    removeFromShortList={this.removeFromShortList}
                                    interviewDate={this.interviewDate}
                                    EditinterviewDate={this.EditinterviewDate}
                                    onChangeSchedule={this.onChangeSchedule}
                                    onEditSchedule={this.onEditSchedule}
                                    schedule={schedule}
                                    cancelInterview={this.cancelInterview}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default AllAplicants;
