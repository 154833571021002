import React from "react";
import Form from "../../../../all/common/form";
import Joi from "joi-browser";
import * as toast from "../../../../all/toast";
import referencesService from "../../../../services/referencesService";
import { setHasChanges } from '../../../../globalVariables';
import _ from 'lodash'

class ReferencesForm extends Form {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                email: "",
                file: "",
                full_name: "",
                id: '',
                organisation: "",
                phone: "",
                user_id: '',
            },
            errors: {},
            loading: false
        };
    }

    componentWillReceiveProps(newProps) {
        this.setState({
            data: {
                email: newProps.data.email,
                file: newProps.data.file,
                full_name: newProps.data.full_name,
                id: newProps.data.id,
                organisation: newProps.data.organisation,
                phone: newProps.data.phone,
                user_id: newProps.data.user_id,
            },
        });
    }

    componentDidUpdate() {
        if (!_.isEqual(this.props.data, this.state.data)) {
            setHasChanges(true, 'reference section');
        }
        else {
            setHasChanges(false);
        }
    }

    schema = {
        user_id: Joi.number().integer().allow("").optional(),
        id: Joi.number().integer().allow("").optional(),
        full_name: Joi.string().required().label("Full Name"),
        organisation: Joi.string().required().label("Organisation"),
        email: Joi.string().required().label("Referents Email"),
        index: Joi.number().integer().allow("").optional(),
        phone: Joi.number().integer().required().label("Phone Number"),
        file: Joi.any()
    };

    submitForm = () => {
        const { data } = this.state;
        this.setState({
            loading: true
        });
        const { id, user_id, full_name, organisation, phone, email, file } = this.state.data;
        if (id) {
            referencesService.references_history(id, user_id, full_name, organisation, phone, email, file).then(({ response }) => {
                this.setState({
                    loading: false,
                });
                this.props.saveHistory(data)
                setHasChanges(false);
                toast.success("Successfully edited references history.");
            })
                .catch(err => {
                    this.setState({
                        loading: false
                    });
                    toast.error("Failed to edit references history");
                });
        } else {
            referencesService.references_history2(user_id, full_name, organisation, phone, email, file).then(({ data: response }) => {
                data.id = response.referenceId;
                this.setState({
                    loading: false,
                    data
                });
                this.props.saveHistory(data)
                setHasChanges(false);
                toast.success("Successfully added references history.");
            })
                .catch(err => {
                    this.setState({
                        loading: false
                    });
                    toast.error("Failed to add references history");
                });
        }
    };

    onDeleteClick(id, index) {
        const { data } = this.state;
        referencesService.references_history_delete(id).then(() => {
            data.id = "";
            this.setState({
                data
            });
            this.props.onDelete(index);
        }).catch(() => {
            toast.error("Failed to edit references history.");
        });
    }

    onchange(selectorFiles) {
        const { data } = this.state
        let newArr = selectorFiles[0];
        data.file = newArr;
        this.setState({
            data
        })
    }
    
    render() {
        const { data, errors, loading } = this.state;
        const { id, full_name, organisation, phone, email, file } = data;
        const { index, canAdd, addMore } = this.props;
        let filename = ""
        if (file) {
            if (file instanceof File) {
                filename = file.filename
            } else if (file) {
                filename = file.split('/')[file.split('/').length - 1]
            }
        }

        return (
            <div className="form-head">
                <form key={index} onSubmit={this.handleSubmit}>
                    {this.renderInput("full_name", null, "full_name", full_name, this.handleChange, errors.full_name, false, "Referents Full Name")}
                    {this.renderInput("organisation", null, "organisation", organisation, this.handleChange, errors.organisation, false, "Organisation")}
                    {this.renderInput("phone", null, "phone", phone, this.handleChange, errors.phone, false, "Phone Number")}
                    {this.renderInput("email", null, "email", email, this.handleChange, errors.email, true, "Referents Email")}
                    {filename &&
                        <span>{filename}</span>
                    }
                    {/* {this.renderUploadInput("files", null, files, 'image', this.handleChange, errors.files, true, 1, true, "If you want to update files, just upload another one up to 10MB")} */}
                    <input type="file" name="file" onChange={(e) => this.onchange(e.target.files)} />
                    <div className="form-buttons">
                        <button type="submit">Save</button>
                        {canAdd && id &&
                            <button type="button" onClick={addMore}> Add More</button>
                        }
                        {id &&
                            <button type="button" onClick={this.onDeleteClick.bind(this, id, index)} >Delete </button>
                        }
                    </div>
                </form>
                {!canAdd && id &&
                    <div className="bottom-line"></div>
                }
            </div>
        );
    }
}

export default ReferencesForm;
