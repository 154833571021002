import React from "react";
import Form from "../../../all/common/form";
import Joi from "joi-browser";
import * as toast from "../../../all/toast";
import profileService from "../../../services/profileService";
import { setHasChanges } from '../../../globalVariables';
import _ from 'lodash'
import TextEditor from '../../userProfile/components/editor';

class ProfSummary extends Form {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: 'profOver',
      data: {
        id: props.id,
        experience_description: ""
      },
      errors: {},
      loading: false,
      maxCounter: 400
    };
  }

  schema = {
    id: Joi.number().integer().allow("").optional(),
    experience_description: Joi.string().min(0).allow("").optional()
  };

  componentDidUpdate() {
    if (!_.isEqual(this.props.data, this.state.data)) {
      setHasChanges(true, 'summary section');
    }
    else {
      setHasChanges(false);
    }
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      data: {
        id: newProps.data.id,
        experience_description: newProps.data.experience_description
      }
    })
  }

  submitForm = () => {
    this.setState({
      loading: true
    });
    const { id, experience_description } = this.state.data;
    profileService.experience_description(id, experience_description).then(({ response }) => {
      this.setState({
        loading: false
      });
      this.props.saveData()
      setHasChanges(false);
      toast.success("Successfully edited experience description.");
    }).catch(err => {
      this.setState({
        loading: false
      });
      toast.error("Failed to edit experience description");
    });
  };


  getData = (val) => {
    let { data } = this.state;
    data.experience_description = val
    this.setState({
      data
    })
  }

  render() {
    const { data, errors, loading, maxCounter } = this.state;
    const { toggleCurrentTab, currentTab, tab } = this.props;
    const { experience_description } = data;
    let counter = maxCounter - experience_description.replace(/<[^>]+>/g, '').length;
    return (
      <React.Fragment>
        <div className={`edit-info`} id='profOver'>
          <div className={`edit-info-click ${currentTab === "profOver" || tab === 'profOver' ? "active" : ""}`} onClick={() => toggleCurrentTab("profOver")}>
            <span>Professional Summary</span>
            <img className="arrowImg" onClick={() => toggleCurrentTab("profOver")} src="../img/arrow.png" alt="" />
          </div>
          <div className={`edit-info-show ${currentTab === "profOver" || tab === 'profOver' ? "active" : ""}`}>
            <form onSubmit={this.handleSubmit}>
              <span className={`countLetters`} >Include 2-3 sentences about your experience ({`${counter}`} characters left)</span>
              <TextEditor
                sendData={this.getData}
                description={experience_description}
                error={errors.experience_description}
              />
              <div className="form-buttons">
                {this.renderSubmitButton("Save", loading, "")}
              </div>
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ProfSummary;
