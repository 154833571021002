import React, { Component } from 'react';
import LisComponent from './listComponent';
import { Link } from 'react-router-dom';

class List extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dropActive: false,
            data: [],
            value: ''
        };
        this.state.data = this.props.users;
    }

    componentDidMount(){
        this.handleSearchScrollJobs();
    }

    handleSearchScrollJobs = () => {
        const ref = this.refs.usersScroll
        ref.addEventListener("scroll", () => {
            if (ref.scrollTop + ref.clientHeight >= ref.scrollHeight) {
                this.props.getUsers('scrollPage');
            }
        });
    }

    render() {
        const { isToggleList, dropdownClick, users, company, last_page } = this.props;
        const { dropActive } = this.state;
        return (
            <React.Fragment>
                <div ref="usersScroll" className={`list ${isToggleList === 'list' ? "on" : "off"}`}>
                    <div className={`top-list`}>
                        <div className="top-list-name">
                            <span>Name</span>
                        </div>
                        <div className="top-list-name">
                            <span>Email</span>
                        </div>
                        <div className="top-list-name">
                            <span>Profile</span>
                        </div>
                        {company === 'comp' ?
                            ''
                            :
                            <div className="top-list-name">
                                <span>Resume</span>
                            </div>
                        }
                    </div>
                    {last_page === 0 &&
                        <span className="notFound">No user found!</span>
                    }
                    <div>
                        {users[0] &&
                            users.map((user, index) =>
                                <LisComponent
                                    indexKey={index}
                                    key={index}
                                    user={user}
                                    dropActive={dropActive}
                                    dropdownClick={dropdownClick}
                                    company={company}
                                />
                            )
                        }
                        {users.length === 0 && last_page > 0 &&
                            Array.from({length: 6}, (item, index) =>  
                                <div key={index} className={`body-list`}>
                                    <div>
                                        <div className={`user-list loading`}>
                                        <div className={`shadow-loading`}></div>
                                            <Link >
                                                <div className="user-list-wrapper name">
                                                    <span></span>
                                                </div>
                                                <div className="user-list-wrapper profession">
                                                    <span></span>
                                                </div>
                                                <div className="user-list-wrapper profession">
                                                    <span></span>
                                                </div>
                                                <div className="user-list-wrapper img">
                                                    <span className="initial">EX</span>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default List;
