import React from 'react';
import Form from '../../../all/common/form';
import Joi from 'joi-browser';
import * as toast from '../../../all/toast';
import employmentService from '../../../services/employmentService';
import { setHasChanges } from '../../../globalVariables';
import { Dropdown } from 'semantic-ui-react'
import startMonthJson from '../../../json/startMonth.json'
import startYearJson from '../../../json/startYear.json'
import _ from 'lodash'
import TextEditor from '../../userProfile/components/editor';

class AddEmpHistory extends Form {

    constructor(props) {
        super(props);
        this.state = {
            data: {
                id: '',
                job_title: '',
                employer_name: '',
                start_month: '',
                start_year: '',
                end_month: '',
                end_year: '',
                job_city: '',
                emp_web: '',
                job_description: '',
                user_id: '',
                checkboxToggle: false
            },
            errors: {},
            loading: false,
            maxCounter: 400
        }
    }

    schema = {
        user_id: Joi.number().integer().allow('').optional(),
        id: Joi.number().integer().allow('').optional(),
        job_title: Joi.string().required().label("Job Title"),
        employer_name: Joi.string().required().label("Employer Name"),
        start_month: Joi.string().required().label("Start Month"),
        start_year: Joi.number().integer().label("Start Year"),
        end_month: Joi.string().allow("").optional().label("End Month"),
        end_year: Joi.number().integer().allow("").optional().label("End Year"),
        job_city: Joi.string().required().label("City"),
        emp_web: Joi.string().allow('').optional(),
        job_description: Joi.string().allow('').optional(),
        index: Joi.number().integer().allow('').optional(),
        checkboxToggle: Joi.boolean().allow('').optional()
    }

    componentWillReceiveProps(newprops) {
        this.setState({
            data: {
                id: newprops.data.id,
                job_title: newprops.data.job_title,
                employer_name: newprops.data.employer_name,
                start_month: newprops.data.start_month,
                start_year: newprops.data.start_year,
                end_month: newprops.data.end_month,
                end_year: newprops.data.end_year,
                job_city: newprops.data.job_city,
                emp_web: newprops.data.emp_web,
                job_description: newprops.data.job_description,
                user_id: newprops.data.user_id,
                checkboxToggle: newprops.data.checkboxToggle,
            }
        })
    }

    componentDidUpdate() {
        if (!_.isEqual(this.props.data, this.state.data)) {
            setHasChanges(true, 'employment section');
        }
        else {
            setHasChanges(false);
        }
    }

    componentWillUpdate() {
        let { data } = this.state
        if (data.checkboxToggle) {
            this.schema.end_month = Joi.string().allow(null, '').optional()
            this.schema.end_year = Joi.string().allow(null, '').optional()
        } else {
            this.schema.end_month = Joi.string().required()
            this.schema.end_year = Joi.string().required()
        }
    }



    onChangeEndDate = date => {
        const { data } = this.state;
        data.end_month = date.toISOString().slice(0, 10).replace('T', ' ');
        this.setState({ data })
    }

    onChangeStartDate = date => {
        const { data } = this.state;
        data.start_month = date.toISOString().slice(0, 10).replace('T', ' ');
        this.setState({ data })
    }

    onChangeCheckBox = event => {
        let { data } = this.state;
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        data.checkboxToggle = value;
        this.setState({
            data
        });
    };

    submitForm = () => {
        const { data } = this.state;
        this.setState({
            loading: true
        })
        const { id, user_id, job_title, employer_name, start_month, end_month, start_year, end_year, job_city, emp_web, job_description, checkboxToggle } = this.state.data;
        if (id) {
            employmentService.employment_history(id, user_id, job_title, employer_name, start_month, end_month, start_year, end_year, job_city, emp_web, job_description, checkboxToggle).then(({ response }) => {
                this.setState({
                    loading: false
                })
                this.props.saveHistory(data)
                setHasChanges(false);
                toast.success("Successfully edited employment history.")
            }).catch(err => {
                this.setState({
                    loading: false
                })
                toast.error("Failed to edit employment history")
            })
        }
        else {
            employmentService.employment_history2(user_id, job_title, employer_name, start_month, end_month, start_year, end_year, job_city, emp_web, job_description, checkboxToggle).then(({ data: response }) => {
                data.id = response.empId;
                this.setState({
                    loading: false,
                    data
                })
                this.props.saveHistory(data)
                setHasChanges(false);
                toast.success("Successfully added employment history.")
            }).catch(err => {
                this.setState({
                    loading: false
                })
                toast.error("Failed to add employment history")
            })
        }
    }

    onDeleteClick(id, index) {
        const { data } = this.state;
        employmentService.employment_history_delete(id).then(() => {
            data.id = '';
            this.setState({
                data
            })
            this.props.onDelete(index);
        }).catch(() => {
            toast.error('Failed to delete employment history.')
        })

    }

    handleChangeStartMonth = (e, { value }) => {
        const { data, errors } = this.state;
        startMonthJson.start_Month.forEach(element => {
            if (element.value === value) {
                data.start_month = element;
            }
        });
        data.start_month = value;
        delete errors.start_month;
        this.setState({
            data
        });
    };
    handleChangeStartYear = (e, { value }) => {
        const { data, errors } = this.state;
        startYearJson.start_year.forEach(element => {
            if (element.value === value) {
                data.start_year = element;
            }
        });
        data.start_year = value;
        delete errors.start_year;
        this.setState({
            data
        });
    };
    handleChangeEndMonth = (e, { value }) => {
        const { data, errors } = this.state;
        startMonthJson.start_Month.forEach(element => {
            if (element.value === value) {
                data.end_month = element;
            }
        });
        data.end_month = value;
        delete errors.end_month;
        this.setState({
            data
        });
    };
    handleChangeEndYear = (e, { value }) => {
        const { data, errors } = this.state;
        startYearJson.start_year.forEach(element => {
            if (element.value === value) {
                data.end_year = element;
            }
        });
        data.end_year = value;
        delete errors.end_year;
        this.setState({
            data
        });
    };

    onClickError = (e) => {
        const elements = document.getElementsByClassName("invalid-tooltip");
        if (!e.target.parentNode.parentNode.className.includes('invalid-tooltip')) {
            if (elements[0]) {
                elements[0].classList.remove("invalid-tooltip");
            }
            e.target.parentNode.parentNode.className += ' invalid-tooltip'
        } else {
            e.target.parentNode.parentNode.className = 'input-form input-control is-invalid'
        }
    }

    getData = (val) => {
        let { data } = this.state;
        data.job_description = val
        this.setState({
            data
        })
    }

    render() {
        const { data, errors, loading, maxCounter } = this.state;
        const { id, job_title, employer_name, start_month, start_year, end_month, end_year, job_city, emp_web, job_description, checkboxToggle } = data;
        const { index, canAdd, addMore } = this.props;
        let counter = maxCounter - job_description.replace(/<[^>]+>/g, '').length;
        return (
            <div className="form-head">
                <form key={index} onSubmit={this.handleSubmit}>
                    {this.renderInput("job_title", null, 'job_title', job_title, this.handleChange, errors.job_title, false, "Job Title")}
                    {this.renderInput("employer_name", null, 'employer_name', employer_name, this.handleChange, errors.employer_name, false, "Employer")}
                    {this.renderInput("job_city", null, 'job_city', job_city, this.handleChange, errors.job_city, false, "City")}
                    {this.renderInput("emp_web", null, 'emp_web', emp_web, this.handleChange, errors.emp_web, false, "Company Web")}
                    <div className={`input-form`}>
                        <Dropdown
                            placeholder='Start Month'
                            onChange={this.handleChangeStartMonth}
                            fluid
                            search
                            selection
                            value={start_month}
                            defaultOpen={false}
                            options={startMonthJson.start_Month}
                        />
                        <div className={`warning-circle ${errors.start_month ? 'is-invalid' : ''}`}>
                            <img onClick={this.onClickError.bind(this)} src="../img/warning-sign.svg" alt="" />
                        </div>
                        <div className={`warning-tooltip `} >
                            <p className={`error-message ${errors.start_month ? 'input-control is-invalid' : 'input-control'}`}>{errors.start_month}</p>
                        </div>
                    </div>
                    <div className={`input-form`}>
                        <Dropdown
                            placeholder='Start Year'
                            onChange={this.handleChangeStartYear}
                            fluid
                            search
                            selection
                            value={start_year}
                            defaultOpen={false}
                            options={startYearJson.start_year}
                        />
                        <div className={`warning-circle ${errors.start_year ? 'is-invalid' : ''}`}>
                            <img onClick={this.onClickError.bind(this)} src="../img/warning-sign.svg" alt="" />
                        </div>
                        <div className={`warning-tooltip `} >
                            <p className={`error-message ${errors.start_year ? 'input-control is-invalid' : 'input-control'}`}>{errors.start_year}</p>
                        </div>
                    </div>
                    <div className={`input-form`}>
                        <Dropdown
                            placeholder='End Month'
                            onChange={this.handleChangeEndMonth}
                            fluid
                            search
                            selection
                            value={end_month}
                            defaultOpen={false}
                            options={startMonthJson.start_Month}
                            disabled={checkboxToggle}
                        />
                        <div className={"presentdiv"}>
                            <input className={'checkbox-input'} onClick={this.onChangeCheckBox} type="checkbox" checked={checkboxToggle} />
                            <label >Present</label>
                        </div>
                        {!checkboxToggle &&
                            <React.Fragment>
                                <div className={`errors_end_month warning-circle ${errors.end_month ? 'is-invalid' : ''}`}>
                                    <img onClick={this.onClickError.bind(this)} src="../img/warning-sign.svg" alt="" />
                                </div>
                                <div className={`warning-tooltip `} >
                                    <p className={`error-message ${errors.end_month ? 'input-control is-invalid' : 'input-control'}`}>{errors.end_month}</p>
                                </div>
                            </React.Fragment>
                        }
                    </div>
                    <div className={`input-form`}>
                        <Dropdown
                            placeholder='End Year'
                            onChange={this.handleChangeEndYear}
                            fluid
                            search
                            selection
                            value={end_year}
                            defaultOpen={false}
                            options={startYearJson.start_year}
                            disabled={checkboxToggle}
                        />
                        {!checkboxToggle &&
                            <React.Fragment>
                                <div className={`warning-circle ${errors.end_year ? 'is-invalid' : ''}`}>
                                    <img onClick={this.onClickError.bind(this)} src="../img/warning-sign.svg" alt="" />
                                </div>
                                <div className={`warning-tooltip `} >
                                    <p className={`error-message ${errors.end_year ? 'input-control is-invalid' : 'input-control'}`}>{errors.end_year}</p>
                                </div>
                            </React.Fragment>
                        }
                    </div>
                    <span className={`countLetters`} >Include 2-3 sentences about your experience ({`${counter}`} characters left)</span>
                    <TextEditor
                        sendData={this.getData}
                        description={job_description}
                        error={errors.job_description}
                    />
                    <div className="form-buttons">
                        <button type="submit" >Save</button>
                        {canAdd && id &&
                            <button type="button" onClick={addMore} >Add More</button>
                        }
                        {id &&
                            <button type="button" onClick={this.onDeleteClick.bind(this, id, index)} >Delete</button>
                        }
                    </div>
                </form>
                {!canAdd && id &&
                    <div className="bottom-line"></div>
                }
            </div>
        );
    }
}

export default AddEmpHistory;