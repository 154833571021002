import React from "react";
import Form from "../../../../all/common/form";
import Joi from "joi-browser";
import * as toast from "../../../../all/toast";
import languageService from "../../../../services/languageService";
import { Dropdown } from 'semantic-ui-react'
import languages from '../../../../json/languages.json'
import { setHasChanges } from '../../../../globalVariables';
import _ from 'lodash'

class LanguageForm extends Form {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        id: '',
        user_id: props.id,
        language: "",
        level: "",
      },
      errors: {},
      loading: false
    };
  }

  componentDidUpdate() {
    if (!_.isEqual(this.props.data, this.state.data)) {
      setHasChanges(true, 'language section');
    }
    else {
      setHasChanges(false);
    }
  }

  componentWillReceiveProps(newProp) {
    this.setState({
      data: {
        id: newProp.data.id,
        user_id: newProp.id,
        language: newProp.data.language,
        level: newProp.data.level,
      },
    });
  }

  schema = {
    user_id: Joi.number().integer().allow("").optional(),
    id: Joi.number().integer().allow("").optional(),
    language: Joi.string().required(),
    level: Joi.string().required()
  };

  submitForm = () => {
    const { data } = this.state;
    this.setState({
      loading: true
    });
    const { id, user_id, language, level } = this.state.data;
    if (id) {
      languageService.language_history(id, language, level).then(({ response }) => {
        this.setState({
          loading: false,
        });
        this.props.saveHistory(data)
        setHasChanges(false);
        toast.success("Successfully edited language section.");
      })
        .catch(err => {
          this.setState({
            loading: false
          });
          toast.error("Failed to edit language section.");
        });
    } else {
      languageService.language_history2(user_id, language, level).then(({ data: response }) => {
        data.id = response.languageId;
        this.setState({
          loading: false,
          data
        });
        this.props.saveHistory(data)
        setHasChanges(false);
        toast.success("Successfully added language section.");
      })
        .catch(err => {
          this.setState({
            loading: false
          });
          toast.error("Failed to add language section.");
        });
    }
    this.props.addMore();
  };

  onDeleteClick(id, index) {
    const { data } = this.state;
    languageService.language_history_delete(id).then(() => {
      data.id = "";
      this.setState({
        data
      });
      this.props.onDelete(index);
    }).catch(() => {
      toast.error("Failed to delete language section.");
    });
  }

  handleChangeLanguage = (e, { value }) => {
    const { data, errors } = this.state;
    data.language = value;
    delete errors.language;
    this.setState({
      data
    });
  };

  handleChangeLevel = (e, { value }) => {
    const { data, errors } = this.state;
    data.level = value;
    delete errors.level;
    this.setState({
      data
    });
  };

  onClickError = (e) => {
    const elements = document.getElementsByClassName("invalid-tooltip");
    if (!e.target.parentNode.parentNode.className.includes('invalid-tooltip')) {
      if (elements[0]) {
        elements[0].classList.remove("invalid-tooltip");
      }
      e.target.parentNode.parentNode.className += ' invalid-tooltip'
    } else {
      e.target.parentNode.parentNode.className = 'input-form input-control is-invalid'
    }
  }

  render() {
    const { data, loading, errors } = this.state;
    const { id, language, level } = data;
    const { index, canAdd, addMore } = this.props;
    return (
      <div className="form-head">
        <form key={index} onSubmit={this.handleSubmit}>
          <div className={`input-form grid-2`}>
            <Dropdown
              placeholder='Select Language'
              onChange={this.handleChangeLanguage}
              fluid
              search
              selection
              value={language}
              options={languages.languagesOptions}
            />
            <div className={`warning-circle ${errors.language ? 'is-invalid' : ''}`}>
              <img onClick={this.onClickError.bind(this)} src="../img/warning-sign.svg" alt="" />
            </div>
            <div className={`warning-tooltip `} >
              <p className={`error-message ${errors.language ? 'input-control is-invalid' : 'input-control'}`}>{errors.language}</p>
            </div>
          </div>
          <div className={`input-form  grid-2`}>
            <Dropdown
              placeholder='Select Level'
              onChange={this.handleChangeLevel}
              fluid
              search
              selection
              value={level}
              options={languages.levelOptions}
            />
            <div className={`warning-circle ${errors.level ? 'is-invalid' : ''}`}>
              <img onClick={this.onClickError.bind(this)} src="../img/warning-sign.svg" alt="" />
            </div>
            <div className={`warning-tooltip `} >
              <p className={`error-message ${errors.level ? 'input-control is-invalid' : 'input-control'}`}>{errors.level}</p>
            </div>
          </div>
          <div className="form-buttons">
            <button type="submit" >Save</button>
            {canAdd && id &&
              <button type="button" onClick={addMore}> Add More</button>
            }
            {id &&
              <button type="button" onClick={this.onDeleteClick.bind(this, id, index)}>Delete</button>
            }
          </div>
        </form>
        {!canAdd && id &&
          <div className="bottom-line"></div>
        }
      </div>

    );
  }
}



export default LanguageForm;
